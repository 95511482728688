<template>
  <div class="project-card">
    <div class="portfolio-background">
      <div
        class="portfolio-banner"
        :style="{
          backgroundImage: `url(${portfolio.banner})`,
        }"
      ></div>
    </div>
    <div class="portfolio-details">
      <div class="portfolio-description">
        {{ portfolio.description }}
      </div>
      <div class="portfolio-actions">
        <div class="view-portfolio">
          <a :href="portfolio.link" target="_blank">
            <img src="/img/onboarding/external-link.svg" alt="" />
          </a>
        </div>
        <div class="remove-portfolio">
          <div @click="deleteAPortfolio(portfolio.id)">
            <img src="/img/dashboard/new-trash-icon.svg" alt="" />
          </div>
          <div @click="hanleEditPortfolio(portfolio)">
            <img src="/img/dashboard/edit-icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    hanleEditPortfolio: {
      type: Function,
      required: true,
    },
    deleteAPortfolio: {
      type: Function,
    },
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.project-card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
}
.card-content {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 12px;
}
.project-link {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 12px;
  cursor: pointer;
}
.bottom-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
.remove-btn {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-right: 32px;
  cursor: pointer;
}
.edit-btn {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  cursor: pointer;
}
.portfolio-background {
  width: 183px;
  height: 149px;
}
.portfolio-banner {
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  background-position: top;
}
.portfolio-details {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.portfolio-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  width: 381px;
}
.portfolio-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.portfolio-actions img {
  width: 20px;
  height: 20px;
}
.remove-portfolio {
  display: flex;
  gap: 24px;
}
.remove-portfolio img {
  cursor: pointer;
}
@media (max-width: 768px) {
  .portfolio-description {
    width: unset;
  }

  .project-card {
    flex-direction: column;
  }
}
</style>
