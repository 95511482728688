var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Toolbar",
        _vm._b(
          {},
          "Toolbar",
          {
            tabs: _vm.tabs,
            activeTab: _vm.activeTab,
            switchTabs: _vm.switchTabs,
          },
          false
        ),
        [
          _vm.activeTab == "portfolio"
            ? _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: {
                      click: function ($event) {
                        return _vm.addPortfolio()
                      },
                    },
                  },
                  [_vm._v("\n        Add Product\n      ")]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm.teamData == null
        ? _c("Loader")
        : _c("div", { staticClass: "team-mgt" }, [
            _vm.activeTab == "profile"
              ? _c("div", [_c("TeamProfile")], 1)
              : _vm._e(),
            _vm.activeTab == "portfolio"
              ? _c(
                  "div",
                  { staticClass: "completed" },
                  [_c("TeamPortfolio", { ref: "teamPortfolio" })],
                  1
                )
              : _vm._e(),
            _vm.activeTab == "service_plans"
              ? _c("div", [_c("TeamServicePlans")], 1)
              : _vm._e(),
          ]),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }