<template>
  <div>
    <div class="vetting-progress">
      <div class="progress-wrapper">
        <div class="" v-for="(stage, k) in vettingStages" :key="k">
          <!-- <div v-if="k == 0"> -->
          <div class="progress-stage" @click="switchStage(k)">
            <img
              :src="`/img/onboarding/${
                currentStage == k
                  ? 'green-dot.svg'
                  : currentStage < k
                  ? 'black-dot.svg'
                  : 'tick-icon.svg'
              }`"
              alt=""
            />
            <div
              class="progress-name"
              :style="
                currentStage == k
                  ? 'color: #1fa564;'
                  : currentStage < k
                  ? 'color: #979da5;'
                  : 'color: #1b1e22;'
              "
            >
              {{ stage }}
            </div>
          </div>
          <div
            class="progress-line"
            v-if="k + 1 < vettingStages.length"
            :style="
              currentStage == k
                ? 'border-left: solid 2px #54CF94;'
                : currentStage < k
                ? 'border-left:solid 2px #e4e5e7;'
                : 'border-left: solid 3px #1b1e22'
            "
          ></div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      vettingStages: [
        "Create your team",
        "Share your portfolio",
        "Invite teammates",
        "Talk to us",
      ],
    };
  },
  created() {},
  methods: {
    ...mapActions("devDashboard", ["updateCurrentStage"]),

    switchStage(value) {
      const activationLevel = this.activationLevel;
      if (Number.isInteger(activationLevel) && value <= activationLevel) {
        this.updateCurrentStage(value);
      }
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo", "currentStage"]),
    ...mapState("miniProject", ["teamMiniProject"]),
    projectResult() {
      return this.teamMiniProject.project_result;
    },
    portfolioResult() {
      return this.teamMiniProject.portfolio_result;
    },
    activationLevel() {
      return this.teamInfo.team.activation_level;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.vetting-progress {
  /* background: #ffffff; */

  background: #f7f7f8;
  width: 307px;
  padding-top: 78px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 64px;
  height: calc(100vh - 64px);
}
.progress-stage {
  display: flex;
  cursor: pointer;
}
.progress-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-left: 4px;
}
.progress-line {
  height: 60px;
  border-left: solid 2px #e4e5e7;
  margin: 7px 0px 10px 3px;
}
@media (max-width: 1280px) {
  .vetting-progress {
    width: 250px;
  }
}
@media (max-width: 768px) {
  .vetting-progress {
    width: unset;
    display: none;
  }
}
</style>


