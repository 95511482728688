var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container-wrapper" }, [
      _c("div", { staticClass: "left-container" }, [
        _c("div", { staticClass: "vetting-title" }, [
          _vm._v("Create your team"),
        ]),
        _c("div", { staticClass: "vetting-description" }, [
          _vm._v(
            "\n        We believe truly great products are built by the best teams. Tell us a\n        bit about the team you’re putting together\n      "
          ),
        ]),
        _c("div", { staticClass: "team-form" }, [
          _c("div", { staticClass: "form-label" }, [
            _c("div", [_vm._v("Team name")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form_errors.team_name[0],
                    expression: "form_errors.team_name[0]",
                  },
                ],
                staticClass: "form_errors",
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.form_errors.team_name[0]) +
                    "\n          "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.team_name,
                  expression: "form.team_name",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "name",
                id: "name",
                placeholder: "What name will your team be called?",
              },
              domProps: { value: _vm.form.team_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "team_name", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-label" }, [
            _c("div", [_vm._v("Enter a short bio for your team")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form_errors.bio[0],
                    expression: "form_errors.bio[0]",
                  },
                ],
                staticClass: "form_errors",
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.form_errors.bio[0]) +
                    "\n          "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.bio,
                  expression: "form.bio",
                },
              ],
              staticClass: "form-control",
              staticStyle: { height: "unset" },
              attrs: {
                rows: "4",
                placeholder: "e.g. Mike and I were the first employees at Uber",
              },
              domProps: { value: _vm.form.bio },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "bio", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "global_error" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form_errors.backend,
                    expression: "form_errors.backend",
                  },
                ],
              },
              [_vm._v(_vm._s(_vm.form_errors.backend[0]))]
            ),
          ]),
          _c("div", { staticClass: "submit-btn" }, [
            _c(
              "button",
              {
                staticClass: "grupa-disabled-btn",
                class: _vm.enableBtn ? "grupa-blue-btn" : "",
                attrs: { disabled: !_vm.enableBtn },
                on: { click: _vm.handleCreateTeam },
              },
              [
                _vm._v(
                  "\n            Proceed to Share Portfolio\n            "
                ),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spinner,
                      expression: "spinner",
                    },
                  ],
                  staticClass: "button-spinner",
                  staticStyle: { "margin-left": "20px" },
                  attrs: { src: "/img/lightbox/preloader.gif" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "right-container" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }