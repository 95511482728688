var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "portfolio-card" }, [
    _c("div", { staticClass: "portfolio-wrapper" }, [
      _c("div", {
        staticClass: "portfolio-banner",
        style: {
          backgroundImage: "url(" + _vm.portfolioBanner + ")",
        },
      }),
    ]),
    _c("div", { staticClass: "portfolio-details" }, [
      _c("div", { staticClass: "portfolio-name" }, [
        _vm._v(_vm._s(_vm.portfolio.project)),
      ]),
      _c("div", { staticClass: "portfolio-description" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.truncate(_vm.portfolio.description, 140)) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "portfolio-link" }, [
        _c("a", { attrs: { href: _vm.portfolio.link, target: "_blank" } }, [
          _vm._v(_vm._s(_vm.portfolio.link)),
        ]),
      ]),
      _c("div", { staticClass: "portfolio-industry-wrapper" }, [
        _vm.portfolio.industry
          ? _c("div", { staticClass: "portfolio-industry" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.portfolio.industry) + "\n      "
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "card-actions" }, [
        _c(
          "button",
          {
            staticClass: "grupa-white-btn",
            on: {
              click: function ($event) {
                return _vm.deleteAPortfolio(_vm.portfolio.id)
              },
            },
          },
          [_vm._v("\n        Delete\n      ")]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-white-blue-btn",
            on: {
              click: function ($event) {
                return _vm.editAPortfolio(_vm.portfolio)
              },
            },
          },
          [_vm._v("\n        Edit\n      ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }