var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-card" }, [
    _c("div", { staticClass: "portfolio-background" }, [
      _c("div", {
        staticClass: "portfolio-banner",
        style: {
          backgroundImage: "url(" + _vm.portfolio.banner + ")",
        },
      }),
    ]),
    _c("div", { staticClass: "portfolio-details" }, [
      _c("div", { staticClass: "portfolio-description" }, [
        _vm._v("\n      " + _vm._s(_vm.portfolio.description) + "\n    "),
      ]),
      _c("div", { staticClass: "portfolio-actions" }, [
        _c("div", { staticClass: "view-portfolio" }, [
          _c("a", { attrs: { href: _vm.portfolio.link, target: "_blank" } }, [
            _c("img", {
              attrs: { src: "/img/onboarding/external-link.svg", alt: "" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "remove-portfolio" }, [
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.deleteAPortfolio(_vm.portfolio.id)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: "/img/dashboard/new-trash-icon.svg", alt: "" },
              }),
            ]
          ),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.hanleEditPortfolio(_vm.portfolio)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: "/img/dashboard/edit-icon.png", alt: "" },
              }),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }