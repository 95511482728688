<template>
  <div class="team-workmode-wrapper">
    <div class="top-title">
      <div class="container-title">WORK MODES</div>
      <div class="selected-workmode">
        Your team is available for:
        <span v-for="(plan, i) in activeWorkmode" :key="i">
          <span>{{ `${plan.name.toUpperCase()}` }}</span>
          <span>{{ i == activeWorkmode.length - 2 ? "&" : "," }} </span>
        </span>
        <span>WORK MODES</span>
      </div>
    </div>
    <div class="container-instruction">
      Select which work mode(s) your team would be available for
    </div>
    <div class="workmode-container">
      <div
        class="work-mode-card"
        v-for="(plan, i) in teamAvailableWorkMode"
        :key="i"
        :style="!plan.enabled ? 'opacity: 0.3;' : null"
      >
        <div class="card-header-div">
          <div>
            <img :src="imageLink + plan.img" alt="" />
          </div>
          <div class="workmode-name">{{ plan.name }}</div>
        </div>
        <div class="workmode-description">
          {{ plan.description }}
        </div>
        <div class="workmode-price">
          <img :src="imageLink + 'pricetag.svg'" alt="" />{{ plan.price }}
        </div>
        <div class="workmode-action" @click="handleSwitchWorkMode(plan)">
          <div>
            <img
              v-if="plan.selected"
              :src="imageLink + 'active-toggle.svg'"
              alt=""
            />
            <img v-else :src="imageLink + 'inactive-toggle.svg'" alt="" />
          </div>
          <div v-if="plan.selected" class="available">Available</div>
          <div v-else class="un-available">Unavailabe</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { teamSettings } from "@/api/team";
export default {
  props: {
    teamDetails: {
      required: true,
      type: Object,
    },
    handleUpdateSetting: {
      required: true,
      type: Function,
    },
    createWorkMode: {
      required: true,
      type: Function,
    },
    teamAvailableWorkMode: {
      required: true,
      type: Array,
    },
    updateTeamWorkMode: {
      required: true,
      type: Function,
    },
  },
  components: {},

  data: () => ({
    activeMode: true,
    imageLink: "/img/dashboard/workmode/",
  }),

  created() {},
  methods: {
    handleSwitchWorkMode(plan) {
      if (plan.enabled) {
        if (!plan.rowId) {
          const payload = { project_work_mode_id: plan.id, service_plan_id: 1 };
          this.createWorkMode(payload);
        } else {
          const payload = { id: plan.rowId, active: !plan.selected };
          this.updateTeamWorkMode(payload);
        }
      }
    },
  },
  computed: {
    activeWorkmode() {
      return this.teamAvailableWorkMode.filter((plan) => plan.selected == true);
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.team-workmode-wrapper {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 24px;
  text-align: left;
}
.top-title {
  display: flex;
  justify-content: space-between;
}
.container-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.selected-workmode {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
}
.selected-workmode span {
  color: #1fa564;
}
.container-instruction {
  font-size: 14px;
  line-height: 130%;
  margin-top: 24px;
  color: #1b1e22;
}
.workmode-container {
  display: flex;
  margin-top: 16px;
}
.work-mode-card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px 19px 19px 24px;
  width: 365px;
  margin-right: 16px;
}
.card-header-div {
  display: flex;
}
.workmode-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 12px;
  padding-top: 3px;
}
.workmode-description {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
}
.workmode-price {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 12px;
}
.workmode-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
  cursor: pointer;
}
.un-available,
.available {
  margin-top: 2px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 8px;
}
.un-available {
  color: #53585f;
}
</style>
