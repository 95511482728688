var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.isNewPortfolio ? "Add" : "Update") +
              " Product\n      "
          ),
        ]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "modal-content-div" }, [
          _c("input", {
            staticClass: "input_file",
            attrs: {
              type: "file",
              accept: "image/*",
              name: "somename",
              size: "chars",
            },
            on: { change: _vm.onFileChange },
          }),
          _vm.imageURL == "" || _vm.imageURL == "nil"
            ? _c(
                "div",
                {
                  staticClass: "add-image-div",
                  on: {
                    click: function ($event) {
                      return _vm.openFile()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: "/img/dashboard/banner-avatar.svg", alt: "" },
                  }),
                  _vm._m(0),
                ]
              )
            : _vm._e(),
          _vm.imageURL && _vm.imageURL !== "nil"
            ? _c(
                "div",
                {
                  staticClass: "image-cover",
                  on: {
                    click: function ($event) {
                      return _vm.openFile()
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "uploaded-image",
                    style: { backgroundImage: "url(" + _vm.imageURL + ")" },
                  }),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "form-container" }, [
            _c("div", { staticClass: "form-error" }, [
              _vm.form_errors.description
                ? _c("span", { staticClass: "error_span" }, [
                    _vm._v(_vm._s(_vm.form_errors.description[0])),
                  ])
                : _vm._e(),
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.portfolioForm.description,
                  expression: "portfolioForm.description",
                },
              ],
              staticClass: "port-textarea",
              attrs: {
                name: "",
                rows: "4",
                placeholder: "Enter product description",
              },
              domProps: { value: _vm.portfolioForm.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.portfolioForm,
                    "description",
                    $event.target.value
                  )
                },
              },
            }),
            _c("div", { staticClass: "form-error" }, [
              _vm.form_errors.link
                ? _c("span", { staticClass: "error_span" }, [
                    _vm._v(_vm._s(_vm.form_errors.link[0])),
                  ])
                : _vm._e(),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.portfolioForm.link,
                  expression: "portfolioForm.link",
                },
              ],
              staticClass: "port-textbox",
              attrs: { type: "text", placeholder: "Enter link to product" },
              domProps: { value: _vm.portfolioForm.link },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.portfolioForm, "link", $event.target.value)
                },
              },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          { staticClass: "grupa-white-btn", on: { click: _vm.closeDialog } },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-blue-btn",
            staticStyle: { "margin-left": "8px" },
            on: {
              click: function ($event) {
                return _vm.handleAddPortfolio()
              },
            },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.isNewPortfolio ? "Add Product" : "Update") +
                "\n        "
            ),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.spinner,
                  expression: "spinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-info" }, [
      _c("div", [_vm._v("Click to upload product photo")]),
      _c("div", { staticClass: "image-recommended" }, [
        _vm._v(
          "\n              We recommend that photo should be atleast 2400 x 3200 pixels\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }