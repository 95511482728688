<template>
  <div class="portfolio-card">
    <div class="portfolio-wrapper">
      <div
        class="portfolio-banner"
        :style="{
          backgroundImage: `url(${portfolioBanner})`,
        }"
      ></div>
    </div>
    <div class="portfolio-details">
      <div class="portfolio-name">{{ portfolio.project }}</div>
      <div class="portfolio-description">
        {{ truncate(portfolio.description, 140) }}
      </div>
      <div class="portfolio-link">
        <a :href="portfolio.link" target="_blank">{{ portfolio.link }}</a>
      </div>
      <div class="portfolio-industry-wrapper">
        <div v-if="portfolio.industry" class="portfolio-industry">
          {{ portfolio.industry }}
        </div>
      </div>
      <div class="card-actions">
        <button class="grupa-white-btn" @click="deleteAPortfolio(portfolio.id)">
          Delete
        </button>
        <button class="grupa-white-blue-btn" @click="editAPortfolio(portfolio)">
          Edit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import truncate from "@/mixins/truncate";
export default {
  mixins: [truncate],
  props: {
    portfolio: {
      type: Object,
    },
    deleteAPortfolio: {
      type: Function,
    },
    editAPortfolio: {
      type: Function,
      required: true,
    },
  },
  components: {},
  data: () => ({
    portBanner: "/img/onboarding/company/bg1.png",
  }),

  created() {},
  methods: {},
  computed: {
    portfolioBanner() {
      return this.portfolio.banner.includes("http")
        ? this.portfolio.banner
        : this.$portfolioUrl + this.portfolio.banner;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.portfolio-card {
  background: #ffffff;
  border-radius: 4px;
  width: 381px;
  padding: 12px;
  margin: 0px 16px 24px 0px;
}
.portfolio-wrapper {
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
  height: 241px;
}
.portfolio-banner {
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  background-position: top;
}
.portfolio-details {
  padding: 24px 12px 0px 12px;
  text-align: left;
}
.portfolio-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.portfolio-description {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
  height: 57px;
}
.portfolio-link {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  margin-top: 16px;
}
.portfolio-industry-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
.portfolio-industry {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin: 0px 0px 8px 0px;
}
.card-actions {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
.portfolio-link a {
  text-decoration: none;
}
</style>
