
<template>
  <div class="single-portfolio">
    <div class="image-wrapper">
      <div
        class="portfolio-banner"
        :style="{
          backgroundImage: `url(${portfolio.image})`,
        }"
      ></div>
    </div>
    <div class="portfolio-details">
      <div class="portfolio-name">
        {{ portfolio.title }}
      </div>
      <div class="portfolio-description">
        {{ truncate(portfolio.description, 120) }}
      </div>
      <div class="view-portfolio">
        {{ portfolio.url }}
      </div>
    </div>
  </div>
</template>

<script>
import truncate from "@/mixins/truncate";
export default {
  mixins: [truncate],
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },
  components: {},

  data: () => ({
    banner: "/img/onboarding/company/bg1.png",
    description: `Grupa offers software for startups to connect with product teams and workflow system to navigate the process of product planning, road-mapping, design, and collaborative execution to build their products.`,
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.single-portfolio {
  width: 296.82px;
  /* height: 287.94px; */
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
.image-wrapper {
  height: 200px;
}
.portfolio-banner {
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  background-position: top;
}
.portfolio-details {
  padding: 16px;
  text-align: left;
}
.portfolio-name {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.portfolio-description {
  margin-top: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.view-portfolio {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  margin-top: 27px;
}
</style>