<template>
  <div>
    <div v-if="currentUser.team_lead == 1">
      <TeamVetting v-if="team.activation_level < 8" />
      <TeamData v-if="team.activation_level == 8" />
    </div>
    <div v-if="currentUser.team_lead == 0">
      <TeamData />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TeamVetting from "./vetting";
import TeamData from "./teamData";
export default {
  components: {
    TeamVetting,
    TeamData,
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    logOut() {
      localStorage.removeItem("grupa_user");
      this.$router.push({
        name: "login",
      });
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    ...mapState("miniProject", ["teamMiniProject"]),
    currentUser() {
      return this.teamInfo.user;
    },
    team() {
      return this.teamInfo.team;
    },
  },
};
</script>

<style scoped>
</style>


