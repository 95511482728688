var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "team-servicesplan-wapper" },
    [
      _c("div", { staticClass: "section-title" }, [_vm._v("How you’ll work")]),
      _c("div", { staticClass: "section-description" }, [
        _vm._v(
          "\n    Select how best you would like to work as a team. Your team would be shown\n    to only clients who select work modes & service plans that match yours.\n  "
        ),
      ]),
      _c(
        "TeamWorkMode",
        _vm._b(
          {},
          "TeamWorkMode",
          {
            teamDetails: _vm.teamDetails,
            handleUpdateSetting: _vm.handleUpdateSetting,
            createWorkMode: _vm.createWorkMode,
            updateTeamWorkMode: _vm.updateTeamWorkMode,
            teamAvailableWorkMode: _vm.teamAvailableWorkMode,
          },
          false
        )
      ),
      _c(
        "ServicePlans",
        _vm._b(
          {},
          "ServicePlans",
          {
            teamDetails: _vm.teamDetails,
            handleUpdateSetting: _vm.handleUpdateSetting,
            teamServicePlans: _vm.teamServicePlans,
            createWorkMode: _vm.createWorkMode,
            updateTeamWorkMode: _vm.updateTeamWorkMode,
          },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }