var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "interview-section" }, [
        _vm._m(0),
        !_vm.oboarding.meeting_start_date
          ? _c("div", { staticClass: "schedule-meeting" }, [
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  on: { click: _vm.handleScheduleMeeting },
                },
                [_vm._v("\n        Schedule Meeting\n      ")]
              ),
            ])
          : _vm._e(),
        _vm.oboarding.meeting_start_date
          ? _c("div", { staticClass: "meeeting-details" }, [
              _c("div", { staticClass: "meeting-time" }, [
                _vm._m(1),
                _c("div", [
                  _vm._v("\n          Meeting scheduled for\n          "),
                  _vm.convertedMeetingDate
                    ? _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.convertedMeetingDate) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "meeting-link" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: _vm.oboarding.hangout_link,
                    },
                  },
                  [
                    _c("div", { staticClass: "join-meeting-div" }, [
                      _vm._v("Join Meeting"),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm.showCalendly
        ? _c(
            "CalendlyModal",
            _vm._b(
              {
                attrs: { teamInfo: _vm.teamBio },
                on: { closeModal: _vm.closeCalendly },
              },
              "CalendlyModal",
              { meetLink: _vm.meetLink },
              false
            )
          )
        : _vm._e(),
      _vm.isMeetingSuccess
        ? _c(
            "MeetingSuccess",
            _vm._b(
              { attrs: { closeModal: _vm.closeSuccessModal } },
              "MeetingSuccess",
              {
                meetingLoader: _vm.meetingLoader,
                meetingPayload: _vm.meetingPayload,
              },
              false
            )
          )
        : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-div" }, [
      _c("div", { staticClass: "congrate-msg" }, [_vm._v("Talk to us")]),
      _c("div", { staticClass: "text-grey" }, [
        _vm._v(
          "\n        Let’s have a chat to better understand your expectations and how Grupa\n        can best serve your needs.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/onboarding/schedule-icon.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }