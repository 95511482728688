var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "nav-wrapper" }, [
      _c("div", { staticStyle: { display: "flex" } }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "nav-items-container" },
          _vm._l(_vm.tabs, function (tab) {
            return _c(
              "div",
              {
                key: tab.id,
                on: {
                  click: function ($event) {
                    return _vm.switchTabs(tab.tab)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "nav-item",
                    class: { "selected-tab": tab.tab == _vm.activeTab },
                  },
                  [_vm._v("\n            " + _vm._s(tab.name) + "\n          ")]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "slot-content", staticStyle: { "margin-top": "9px" } },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "project-space" }, [
      _c("div", { staticClass: "project-name" }, [_vm._v("Team")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }