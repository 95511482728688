<template>
  <div class="form-container">
    <div
      @click="handleRemoveForm"
      class="delete-wrapper"
      v-if="teamPortfolios.length > 0"
    >
      <span>
        <img src="/img/onboarding/trash_icon.svg" />
      </span>
    </div>
    <form @submit="handleUploadProject">
      <div class="form-label">
        About Product
        <span class="span_error">
          {{ formError.description }}
        </span>
      </div>
      <div>
        <textarea
          class="form-control"
          v-model="form.description"
          @input="formError.description = ''"
          rows="4"
          style="height: unset"
          placeholder=""
        >
        </textarea>
      </div>
      <div class="form-label project-link">
        Link to product
        <span class="span_error">
          {{ formError.link }}
        </span>
      </div>
      <div>
        <input
          type="text"
          @input="formError.link = ''"
          v-model="form.link"
          class="form-control"
          placeholder="Kindly provide a link to the product"
          @blur="prefillTextBox"
        />
      </div>
      <div class="button-wrapper">
        <button
          v-if="urlPreviewDetails"
          class="grupa-white-blue-btn"
          style="background: #f7f7f8"
          type="submit"
        >
          Save Product
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="submit_spinner"
          />
        </button>
        <button
          v-if="!urlPreviewDetails"
          class="grupa-white-blue-btn"
          style="background: #f7f7f8"
          type="submit"
        >
          Preview Product
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="submit_spinner"
          />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    form: {
      required: true,
      type: Object,
    },
    formError: {
      required: true,
      type: Object,
    },
    spinner: {
      required: true,
      type: Boolean,
    },
    handleUploadProject: {
      required: true,
      type: Function,
    },
    urlPreviewDetails: {
      required: true,
    },
    submit_spinner: {
      type: Boolean,
      required: true,
    },
    prefillTextBox: {
      type: Function,
      required: true,
    },
    handleRemoveForm: {
      required: true,
      type: Function,
    },
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    teamPortfolios() {
      return this.teamInfo.portfolio;
    },
    enableBtn() {
      if (this.form.description !== "" && this.form.link !== "") {
        return true;
      } else {
        return false;
      }
    },
    isDisplayButton() {
      if (
        this.form.description == "" &&
        (this.form.link == "" || this.form.link == "https://")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.form-container {
  /* width: 521px; */
  text-align: left;
  margin-top: 24px;
  position: relative;
}
.form-label {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.form-label span {
  color: red;
  float: right;
}
.form-control {
  width: 410px;
  margin-top: 4px;
}
.project-link {
  margin-top: 24px;
}
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  width: 410px;
  gap: 16px;
}
.delete-wrapper {
  position: absolute;
  right: -40px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .form-control,
  .button-wrapper {
    width: 300px;
  }

  .button-wrapper {
    justify-content: flex-start;
  }
}
</style>
