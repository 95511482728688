<template>
  <div>
    <VettingProgress />
    <Toolbar stageTitle="Team" />
    <div class="section-wrapper">
      <CreateTeam v-if="!currentStage" />
      <SharePortfolio v-if="currentStage == 1" />
      <InviteTeam v-if="currentStage == 2" />
      <TalkToGrupa v-if="currentStage == 3" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { teamMiniProject } from "@/api";
import Toolbar from "./components/toolbar.vue";
import VettingProgress from "./components/progress";
import CreateTeam from "./createTeam";
import SharePortfolio from "./sharePortfolio";
import InviteTeam from "./inviteTeam";
import TalkToGrupa from "./talkToUs";
export default {
  props: {},
  components: {
    Toolbar,
    VettingProgress,
    CreateTeam,
    SharePortfolio,
    InviteTeam,
    TalkToGrupa,
  },

  data: () => ({}),

  async created() {
    await this.getMiniProject();
    this.setOnboardingStage();
  },
  methods: {
    ...mapActions("miniProject", ["setMiniProject", "updateCurrentStage"]),
    ...mapActions("devDashboard", ["updateCurrentStage"]),
    getMiniProject() {
      teamMiniProject()
        .then((response) => {
          this.$developConsole(response.data, "Mini Project");
          this.setMiniProject(response.data);
        })
        .catch((err) => {
          this.$developConsole("Mini Project Err", err.response);
        });
    },
    setOnboardingStage() {
      const { activation_level } = this.teamInfo.team;
      this.updateCurrentStage(activation_level);
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo", "currentStage"]),
    activationLevel() {
      return this.teamInfo.team.activation_level;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.section-wrapper {
  background: #ffffff;
  padding: 48px 32px 72px;
  margin-left: 307px;
  margin-top: 64px;
  min-height: calc(100vh - 64px);
  text-align: left;
}
@media (max-width: 1280px) {
  .section-wrapper {
    margin-left: 250px;
  }
}
@media (max-width: 768px) {
  .section-wrapper {
    margin-left: 0;
  }
}
</style>
