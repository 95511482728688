var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-wrapper" }, [
        _vm._m(0),
        _vm.teamPortfolios.length > 0 && !_vm.isEditProject
          ? _c("div", [
              _c(
                "div",
                { staticClass: "projects-wrapper" },
                _vm._l(_vm.teamPortfolios, function (portfolio, index) {
                  return _c(
                    "ProjectCard",
                    _vm._b(
                      { key: index },
                      "ProjectCard",
                      {
                        portfolio: portfolio,
                        hanleEditPortfolio: _vm.hanleEditPortfolio,
                        deleteAPortfolio: _vm.deleteAPortfolio,
                      },
                      false
                    )
                  )
                }),
                1
              ),
              _vm.isAddForm
                ? _c("div", { staticClass: "page-divider" })
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.isAddForm || _vm.teamPortfolios.length == 0
          ? _c(
              "div",
              { staticClass: "past-project-wrapper" },
              [
                _c(
                  "ProjectFrom",
                  _vm._b(
                    {},
                    "ProjectFrom",
                    {
                      form: _vm.form,
                      handleUploadProject: _vm.handleUploadProject,
                      urlPreviewDetails: _vm.urlPreviewDetails,
                      formError: _vm.formError,
                      proceedToNextStage: _vm.proceedToNextStage,
                      prefillTextBox: _vm.prefillTextBox,
                      spinner: _vm.spinner,
                      submit_spinner: _vm.submit_spinner,
                      handleRemoveForm: _vm.handleRemoveForm,
                    },
                    false
                  )
                ),
                _vm.urlPreviewDetails
                  ? _c(
                      "div",
                      [
                        _c("PreviewProject", {
                          attrs: { portfolio: _vm.urlPreviewDetails },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        !_vm.isAddForm && _vm.teamPortfolios.length > 0
          ? _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "grupa-white-btn",
                  on: {
                    click: function ($event) {
                      _vm.isAddForm = true
                    },
                  },
                },
                [_vm._v("\n        + Add Another\n      ")]
              ),
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  staticStyle: { background: "#f7f7f8" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.proceedToNextStage(2)
                    },
                  },
                },
                [
                  _vm._v("\n        Save & Proceed\n        "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.spinner,
                        expression: "spinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm.openDialog
        ? _c(
            "DeletePortfolio",
            _vm._b(
              {
                attrs: {
                  spinner: _vm.delete_spinner,
                  handleDelete: _vm.handleDeletePortfolio,
                },
              },
              "DeletePortfolio",
              { closeDialog: _vm.closeDialog },
              false
            )
          )
        : _vm._e(),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stage-description" }, [
      _c("div", { staticClass: "vetting-title" }, [
        _vm._v("Share your portfolio"),
      ]),
      _c("div", { staticClass: "other-info" }, [
        _vm._v(
          "\n        Tell us about projects/products at least 2 members of the team have\n        worked together on.\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }