var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-container" }, [
    _vm.teamPortfolios.length > 0
      ? _c(
          "div",
          {
            staticClass: "delete-wrapper",
            on: { click: _vm.handleRemoveForm },
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _c("form", { on: { submit: _vm.handleUploadProject } }, [
      _c("div", { staticClass: "form-label" }, [
        _vm._v("\n      About Product\n      "),
        _c("span", { staticClass: "span_error" }, [
          _vm._v("\n        " + _vm._s(_vm.formError.description) + "\n      "),
        ]),
      ]),
      _c("div", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.description,
              expression: "form.description",
            },
          ],
          staticClass: "form-control",
          staticStyle: { height: "unset" },
          attrs: { rows: "4", placeholder: "" },
          domProps: { value: _vm.form.description },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "description", $event.target.value)
              },
              function ($event) {
                _vm.formError.description = ""
              },
            ],
          },
        }),
      ]),
      _c("div", { staticClass: "form-label project-link" }, [
        _vm._v("\n      Link to product\n      "),
        _c("span", { staticClass: "span_error" }, [
          _vm._v("\n        " + _vm._s(_vm.formError.link) + "\n      "),
        ]),
      ]),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.link,
              expression: "form.link",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "Kindly provide a link to the product",
          },
          domProps: { value: _vm.form.link },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "link", $event.target.value)
              },
              function ($event) {
                _vm.formError.link = ""
              },
            ],
            blur: _vm.prefillTextBox,
          },
        }),
      ]),
      _c("div", { staticClass: "button-wrapper" }, [
        _vm.urlPreviewDetails
          ? _c(
              "button",
              {
                staticClass: "grupa-white-blue-btn",
                staticStyle: { background: "#f7f7f8" },
                attrs: { type: "submit" },
              },
              [
                _vm._v("\n        Save Product\n        "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.submit_spinner,
                      expression: "submit_spinner",
                    },
                  ],
                  staticClass: "button-spinner",
                  staticStyle: { "margin-left": "20px" },
                  attrs: { src: "/img/lightbox/preloader.gif" },
                }),
              ]
            )
          : _vm._e(),
        !_vm.urlPreviewDetails
          ? _c(
              "button",
              {
                staticClass: "grupa-white-blue-btn",
                staticStyle: { background: "#f7f7f8" },
                attrs: { type: "submit" },
              },
              [
                _vm._v("\n        Preview Product\n        "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.submit_spinner,
                      expression: "submit_spinner",
                    },
                  ],
                  staticClass: "button-spinner",
                  staticStyle: { "margin-left": "20px" },
                  attrs: { src: "/img/lightbox/preloader.gif" },
                }),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/img/onboarding/trash_icon.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }