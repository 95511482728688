<template>
  <div>
    <div class="container-wrapper">
      <div class="stage-description">
        <div class="vetting-title">Share your portfolio</div>
        <div class="other-info">
          Tell us about projects/products at least 2 members of the team have
          worked together on.
        </div>
      </div>
      <div v-if="teamPortfolios.length > 0 && !isEditProject">
        <div class="projects-wrapper">
          <ProjectCard
            v-for="(portfolio, index) in teamPortfolios"
            v-bind="{ portfolio, hanleEditPortfolio, deleteAPortfolio }"
            :key="index"
          />
        </div>
        <div v-if="isAddForm" class="page-divider"></div>
      </div>
      <div
        v-if="isAddForm || teamPortfolios.length == 0"
        class="past-project-wrapper"
      >
        <ProjectFrom
          v-bind="{
            form,
            handleUploadProject,
            urlPreviewDetails,
            formError,
            proceedToNextStage,
            prefillTextBox,
            spinner,
            submit_spinner,
            handleRemoveForm,
          }"
        />
        <div v-if="urlPreviewDetails">
          <PreviewProject v-bind:portfolio="urlPreviewDetails" />
        </div>
      </div>
      <div
        v-if="!isAddForm && teamPortfolios.length > 0"
        class="button-wrapper"
      >
        <button class="grupa-white-btn" @click="isAddForm = true">
          + Add Another
        </button>
        <button
          class="grupa-blue-btn"
          style="background: #f7f7f8"
          type="button"
          @click="proceedToNextStage(2)"
        >
          Save & Proceed
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="spinner"
          />
        </button>
      </div>
    </div>
    <DeletePortfolio
      v-if="openDialog"
      v-bind="{ closeDialog }"
      v-bind:spinner="delete_spinner"
      v-bind:handleDelete="handleDeletePortfolio"
    />
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>

<script>
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import Toolbar from "../components/toolbar";
import ProjectFrom from "./partials/projectForm";
import ProjectCard from "./partials/projectCard";
import PreviewProject from "./partials/projectPreview.vue";
import {
  uploadOnboardingPortfolio,
  editPortfolio,
  updateActivationLevel,
} from "@/api";
import { deletePortfolio } from "@/api/team";
import DeletePortfolio from "@/components/Modals/deletePortfolio";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import mixPanel from "@/mixins/mixPanel";

export default {
  props: {
    // spinner: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  components: {
    Toolbar,
    ProjectFrom,
    ProjectCard,
    AlertSuccess,
    AlertError,
    DeletePortfolio,
    PreviewProject,
  },
  mixins: [mixPanel],
  data: () => ({
    openDialog: false,
    form: {
      description: "",
      link: "https://",
      project: "",
      banner: "",
    },
    formError: {
      description: "",
      link: "",
    },
    submit_spinner: false,
    isEditProject: false,
    delPortfolioId: 0,
    delete_spinner: false,
    urlPreviewDetails: null,
    btn_spinner: false,
    spinner: false,
    isAddForm: false,
  }),

  created() {},
  methods: {
    ...mapActions("devDashboard", [
      "addPortfolio",
      "updateTeamPortfolio",
      "updateTeamActivationLevel",
      "updateCurrentStage",
    ]),
    ...mapActions("alertMsg", [
      "clearErrorMsg",
      "setErrorMsg",
      "setSuccessMsg",
    ]),

    handleUploadProject(event) {
      event.preventDefault();
      const { description, link, id } = this.form;
      const checkLink = /^http/;
      if (description == "" || link == "") {
        this.formError.description = "This text field cannot be empty";
        this.formError.link = "This text field cannot be empty";
      }
      if (checkLink.test(link) == false) {
        this.formError.link = "This is not a valid link";
      }
      if (description != "" && checkLink.test(link) == true) {
        this.submit_spinner = true;
        let payload = this.form;
        if (id) {
          this.editExistingPortfolio(payload);
        } else if (!this.urlPreviewDetails) {
          this.getImagePreview();
        } else if (this.urlPreviewDetails) {
          this.uploadNewPortfolio(payload);
        }
      }
    },
    uploadNewPortfolio(payload) {
      const { title: project, image: banner } = this.urlPreviewDetails;
      payload.banner = banner;
      payload.project = project;
      uploadOnboardingPortfolio(payload)
        .then((response) => {
          if (response.data.status == 1) {
            this.addPortfolio(response.data.data);
            this.setSuccessMsg("Project Uploaded Successfully");
            this.form.description = "";
            this.form.link = "https://";
            this.urlPreviewDetails = null;
            this.mixPanelTrack({
              event: "UploadPastProject",
              email: this.currentUser.email,
            });
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.submit_spinner = false;
        });
    },
    editExistingPortfolio(payload) {
      editPortfolio(payload.id, payload)
        .then((response) => {
          if (response.data.status == 1) {
            let portfolioList = JSON.parse(JSON.stringify(this.teamPortfolios));
            let portIndex = portfolioList.findIndex(
              (port) => port.id == payload.id
            );
            portfolioList[portIndex] = response.data.data;
            this.updateTeamPortfolio(portfolioList);
            this.setSuccessMsg("Portfolio Updated");
            this.form.description = "";
            this.form.link = "https://";
            this.isEditProject = false;
            this.mixPanelTrack({
              event: "EditPastProject",
              email: this.currentUser.email,
            });
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.submit_spinner = false;
        });
    },
    handleSubmitProject() {},
    hanleEditPortfolio(project) {
      this.form = { ...project };
      this.isEditProject = true;
    },
    closeDialog() {
      this.openDialog = false;
    },
    deleteAPortfolio(id) {
      this.delPortfolioId = id;
      this.openDialog = true;
    },
    handleDeletePortfolio() {
      this.delete_spinner = true;
      let id = this.delPortfolioId;
      deletePortfolio(id)
        .then((resp) => {
          let portfolioList = JSON.parse(JSON.stringify(this.teamPortfolios));
          let portIndex = portfolioList.findIndex((port) => port.id == id);
          portfolioList.splice(portIndex, 1);
          this.updateTeamPortfolio(portfolioList);
          this.setSuccessMsg("Portfolio Deleted!!");
        })
        .catch((err) => {
          this.setErrorMsg("Please try again!!");
        })
        .finally(() => {
          this.openDialog = false;
          this.delete_spinner = false;
        });
    },
    getImagePreview() {
      Axios.get(
        `https://api.linkpreview.net/?key=9bfe0c920ff8bb85bc2530db6f5e8f07&q=${this.form.link}`
      )
        .then((response) => {
          if (response.status == 200) {
            this.urlPreviewDetails = response.data;
          } else {
            this.formError.link = "Invalid url";
          }
        })
        .catch((error) => {
          this.formError.link = "Invalid url";
        })
        .finally(() => {
          this.submit_spinner = false;
        });
    },
    proceedToNextStage(stage) {
      if (this.activationLevel < 2) {
        this.spinner = true;
        let payload = { team_activation_level: stage };
        updateActivationLevel(payload)
          .then((response) => {
            this.spinner = false;
            this.updateTeamActivationLevel(response.data.data);
            this.updateCurrentStage(2);
            this.mixPanelTrack({
              event: "ProceedToInviteTeamMembers",
            });
          })
          .catch((err) => {
            this.spinner = false;
            this.$developConsole("Mini Project Err", err.response);
          });
      } else {
        this.updateCurrentStage(2);
      }
    },
    prefillTextBox() {
      if (this.form.link == "") {
        this.form.link = "https://";
      }
    },
    handleRemoveForm() {
      this.isAddForm = false;
    },
  },
  computed: {
    ...mapState("alertMsg", ["errorMsg", "successMsg"]),
    ...mapState("devDashboard", ["teamInfo"]),

    activationLevel() {
      return this.teamInfo.team.activation_level;
    },
    teamPortfolios() {
      return this.teamInfo.portfolio;
    },
    currentUser() {
      return this.teamInfo.user;
    },
    enableBtn() {
      if (this.teamPortfolios.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.page-container {
  background: #f7f7f8;
  padding: 48px 32px 72px;
  margin-left: 307px;
  margin-top: 64px;
  min-height: calc(100vh - 64px);
}
.stage-description {
  text-align: left;
  width: 521px;
}
.vetting-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #1b1e22;
}
.stage-information {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 8px;
}
.other-info {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 4px;
  width: 478px;
}
.page-divider {
  margin-top: 24px;
  border-top: 1px solid #e4e5e7;
}
.projects-wrapper {
  display: flex;
  gap: 24px;
  margin-top: 24px;
  flex-wrap: wrap;
}
.btn-wrap {
  padding-top: 9px;
  display: flex;
}
.past-project-wrapper {
  display: flex;
  justify-content: space-between;
}
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  width: 596px;
  gap: 16px;
}
@media (max-width: 1280px) {
  .projects-wrapper {
    gap: 12px;
  }
  .page-container {
    margin-left: 250px;
  }
}
@media (max-width: 1440px) {
  .projects-wrapper {
    gap: 12px;
  }
}
@media (max-width: 768px) {
  .stage-description {
    width: unset;
  }
  .other-info {
    width: unset;
  }
  .button-wrapper {
    width: unset;
  }
  .past-project-wrapper {
    flex-direction: column;
  }
}
</style>
