var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p" },
    [
      _vm._m(0),
      _c("div", { staticClass: "page-row" }, [
        _c("div", { staticClass: "items" }, [
          _c("div", { staticClass: "item-cover" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "first" }, [
                _vm._v("INTRODUCTORY VIDEO"),
              ]),
              _c(
                "span",
                {
                  staticClass: "second mouse",
                  on: {
                    click: function ($event) {
                      return _vm.openFile()
                    },
                  },
                },
                [_vm._v("Upload")]
              ),
              _c("input", {
                staticClass: "input-file",
                attrs: {
                  type: "file",
                  accept: "video/*",
                  name: "profilePhoto",
                  size: "chars",
                },
                on: { change: _vm.onFileChange },
              }),
            ]),
            !_vm.videoLink
              ? _c("div", { staticClass: "item item-vid" }, [
                  _c("img", { attrs: { src: "/img/placeholder.png" } }),
                ])
              : _c(
                  "div",
                  { staticClass: "iframe-wrapper" },
                  [
                    _vm.videoSpinner
                      ? _c("v-progress-circular", {
                          staticClass: "video-spinner",
                          attrs: {
                            size: 50,
                            color: "primary",
                            indeterminate: "",
                          },
                        })
                      : _vm._e(),
                    _c("iframe", {
                      attrs: {
                        width: "369",
                        height: "244",
                        src: _vm.videoLink,
                      },
                    }),
                  ],
                  1
                ),
            _c("div", { staticClass: "item vid-text" }, [
              _vm._v(
                "\n              For best presentation, upload a video not longer than 90 seconds.\n              Talk about the makeup, expertise and culture of your team.\n            "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "items" }, [
          _c("div", { staticClass: "item-cover" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", { staticClass: "first" }, [_vm._v("Team Name")]),
              _c(
                "span",
                {
                  staticClass: "second mouse",
                  on: {
                    click: function ($event) {
                      _vm.openTeamDetailsDialog = true
                    },
                  },
                },
                [_vm._v("Edit")]
              ),
            ]),
            _c("div", { staticClass: "item small-top-space" }, [
              _c("span", { staticClass: "first bold" }, [
                _vm._v(_vm._s(_vm.teamObject.team_name)),
              ]),
            ]),
            _vm._m(1),
            _c("div", { staticClass: "item small-top-space" }, [
              _c("span", { staticClass: "first" }, [
                _c("span", { staticClass: "first" }, [
                  _vm._v("\n                    BIO\n                "),
                ]),
                _c("span", { staticClass: "third" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.teamObject.bio) +
                      "\n                "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "page-row full-width" }, [
        _c("div", { staticClass: "items" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "first" }, [
              _vm._v("MEMBERS (" + _vm._s(_vm.members.length) + ")"),
            ]),
            _c(
              "span",
              {
                staticClass: "second mouse",
                on: {
                  click: function ($event) {
                    _vm.openDialog = true
                  },
                },
              },
              [_vm._v("Invite")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "item-cover-2" },
            _vm._l(_vm.members, function (member, k) {
              return _c("div", { key: member.id, staticClass: "item" }, [
                _c("div", { staticClass: "first" }, [
                  member.team_lead == 1
                    ? _c("div", { staticClass: "team-lead-icon" }, [
                        _c("img", {
                          attrs: {
                            src: "/img/dashboard/team-lead.svg",
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "first-block" }, [
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "div",
                        { staticClass: "content-two" },
                        [
                          member.profile_picture
                            ? _c("img", {
                                staticClass: "circle-avatar",
                                attrs: {
                                  src:
                                    _vm.$imageUrl + member.profile_picture ||
                                    "/img/profile.svg",
                                },
                              })
                            : _vm._e(),
                          member.name && !member.profile_picture
                            ? _c(
                                "div",
                                {
                                  staticClass: "profile-avatar",
                                  style:
                                    (k + 1) % 2 == 0
                                      ? "background: #1FA564;"
                                      : null,
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm
                                          .abbrevateName(member.name)
                                          .toUpperCase()
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !member.name
                            ? _c("v-avatar", {
                                staticClass: "profile_bg",
                                style: {
                                  backgroundImage:
                                    "url(" + _vm.imageAvatar + ")",
                                },
                                attrs: { size: 64, color: "grey lighten-4" },
                              })
                            : _vm._e(),
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(member.name)),
                            ]),
                            _c("span", { staticClass: "skill" }, [
                              _vm._v(_vm._s(member.team_role)),
                            ]),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(member.email)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "content-four" }, [
                        _vm.currentUser.team_lead == 1 && member.team_lead == 1
                          ? _c(
                              "span",
                              {
                                staticClass: "view-profile",
                                on: {
                                  click: function ($event) {
                                    return _vm.openTransferTeamLeadModal(member)
                                  },
                                },
                              },
                              [_vm._v("Transfer TeamLead")]
                            )
                          : _vm._e(),
                        !member.team_lead
                          ? _c(
                              "span",
                              {
                                staticClass: "remove-member",
                                on: {
                                  click: function ($event) {
                                    return _vm.openRemoveTeamModal(member)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Remove\n                      "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _vm.openDialog
        ? _c(
            "DialogComponent",
            _vm._b(
              {
                attrs: { dialogTitle: "Invite Teammate" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dialog-footer",
                      fn: function () {
                        return [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "grupa-grey-btn",
                                staticStyle: { "margin-right": "24px" },
                                on: { click: _vm.closeDialog },
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "grupa-blue-btn",
                                on: { click: _vm.inviteTeam },
                              },
                              [
                                _vm._v(
                                  "\n              Send Invite\n              "
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.spinner,
                                      expression: "spinner",
                                    },
                                  ],
                                  staticClass: "button-spinner",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { src: "/img/lightbox/preloader.gif" },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  444336560
                ),
              },
              "DialogComponent",
              { closeDialog: _vm.closeDialog, dialogSize: _vm.dialogSize },
              false
            ),
            [
              _c("div", { attrs: { id: "main-children" } }, [
                _c(
                  "div",
                  {},
                  [
                    _c("span", { staticClass: "modal-inner-title" }, [
                      _vm._v("Enter teammate email & role "),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.invitedUser.email,
                          expression: "invitedUser.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "email",
                        placeholder: "teammate@example.com",
                        type: "email",
                      },
                      domProps: { value: _vm.invitedUser.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.invitedUser,
                            "email",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("v-select", {
                      staticClass: "vue_select",
                      attrs: {
                        items: _vm.roles,
                        label: "-- Select Team Role --",
                        "return-object": "",
                        solo: "",
                        flat: "",
                        light: "",
                        "item-text": "name",
                        "item-value": "id",
                      },
                      model: {
                        value: _vm.invitedUser.teamSelected,
                        callback: function ($$v) {
                          _vm.$set(_vm.invitedUser, "teamSelected", $$v)
                        },
                        expression: "invitedUser.teamSelected",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.openTeamDetailsDialog
        ? _c(
            "DialogComponent",
            _vm._b(
              {
                attrs: { dialogTitle: "Edit Team Info" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dialog-footer",
                      fn: function () {
                        return [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "grupa-grey-btn",
                                staticStyle: { "margin-right": "24px" },
                                on: { click: _vm.closeDialog },
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "grupa-blue-btn",
                                attrs: { disabled: _vm.secSpinnerTwo },
                                on: { click: _vm.editEntireTeam },
                              },
                              [
                                _vm._v(
                                  "\n              Update\n              "
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.secSpinnerTwo,
                                      expression: "secSpinnerTwo",
                                    },
                                  ],
                                  staticClass: "button-spinner",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { src: "/img/lightbox/preloader.gif" },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3779970270
                ),
              },
              "DialogComponent",
              { closeDialog: _vm.closeDialog, dialogSize: _vm.dialogSize },
              false
            ),
            [
              _c("div", { attrs: { id: "main-children" } }, [
                _c("div", {}, [
                  _c("span", { staticClass: "modal-inner-title" }, [
                    _vm._v(
                      "Edit team bio. To edit your team name please Contact Support.\n               "
                    ),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.teamObject.team_name,
                        expression: "teamObject.team_name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "email",
                      placeholder: "Team name",
                      type: "text",
                      disabled: false,
                    },
                    domProps: { value: _vm.teamObject.team_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.teamObject,
                          "team_name",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.teamObject.bio,
                        expression: "teamObject.bio",
                      },
                    ],
                    staticClass: "form-control text-area-form-control",
                    domProps: { value: _vm.teamObject.bio },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.teamObject, "bio", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.openRemoveTeamMateDialog
        ? _c(
            "DialogComponent",
            _vm._b(
              {
                attrs: { dialogTitle: "Remove Teammate" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dialog-footer",
                      fn: function () {
                        return [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "grupa-grey-btn",
                                staticStyle: { "margin-right": "24px" },
                                on: { click: _vm.closeDialog },
                              },
                              [
                                _vm._v(
                                  "\n              No, Go Back\n            "
                                ),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "grupa-blue-btn grupa-btn-red",
                                attrs: { disabled: _vm.secSpinnerTwo },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeTeamMate()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Yes, Remove\n              "
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.secSpinnerTwo,
                                      expression: "secSpinnerTwo",
                                    },
                                  ],
                                  staticClass: "button-spinner",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { src: "/img/lightbox/preloader.gif" },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  593840888
                ),
              },
              "DialogComponent",
              { closeDialog: _vm.closeDialog, dialogSize: _vm.dialogSize },
              false
            ),
            [
              _c("div", { attrs: { id: "main-children" } }, [
                _c("div", {}, [
                  _c("span", { staticClass: "modal-inner-title" }, [
                    _vm._v(
                      " You are about to\n              remove " +
                        _vm._s(
                          _vm.currentToBeRemovedUser.name
                            ? _vm.currentToBeRemovedUser.name
                            : _vm.currentToBeRemovedUser.email
                        ) +
                        " from your team, this can not be reversed."
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "wish-to-continue-txt",
                      staticStyle: {
                        color: "#979DA5",
                        float: "left",
                        "text-align": "left:width:100%",
                      },
                    },
                    [_vm._v("Do you wish to continue?")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.openTransferTeamLeadDialog
        ? _c(
            "DialogComponent",
            _vm._b(
              {
                attrs: { dialogTitle: "Transfer Team Lead" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dialog-footer",
                      fn: function () {
                        return [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "grupa-grey-btn",
                                staticStyle: { "margin-right": "24px" },
                                on: { click: _vm.closeDialog },
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "grupa-blue-btn",
                                attrs: { disabled: _vm.secSpinnerTwo },
                                on: {
                                  click: function ($event) {
                                    return _vm.transfTeamLead()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Transfer\n              "
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.secSpinnerTwo,
                                      expression: "secSpinnerTwo",
                                    },
                                  ],
                                  staticClass: "button-spinner",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { src: "/img/lightbox/preloader.gif" },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2805334927
                ),
              },
              "DialogComponent",
              { closeDialog: _vm.closeDialog, dialogSize: _vm.dialogSize },
              false
            ),
            [
              _c("div", { attrs: { id: "main-children" } }, [
                _c("div", {}, [
                  _c("span", { staticClass: "modal-inner-title" }, [
                    _vm._v("Select A New Team Leader"),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newSelectedTeamLead,
                          expression: "newSelectedTeamLead",
                        },
                      ],
                      staticClass: "select-cont",
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { name: "role" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.newSelectedTeamLead = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "" } }, [
                        _vm._v("-- Select the new team lead --"),
                      ]),
                      _vm._l(_vm.members, function (member) {
                        return _c(
                          "option",
                          { key: member.id, domProps: { value: member } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(member.name) +
                                ", " +
                                _vm._s(member.email) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.openViewProfileDialog
        ? _c(
            "DialogComponent",
            _vm._b(
              {
                attrs: { dialogTitle: "Member name" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dialog-footer",
                      fn: function () {
                        return [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "grupa-grey-btn",
                                staticStyle: { "margin-right": "24px" },
                                on: { click: _vm.closeDialog },
                              },
                              [_vm._v("\n              Cancel\n            ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "grupa-blue-btn",
                                attrs: { disabled: _vm.secSpinnerTwo },
                                on: {
                                  click: function ($event) {
                                    return _vm.transfTeamLead()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Transfer\n              "
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.secSpinnerTwo,
                                      expression: "secSpinnerTwo",
                                    },
                                  ],
                                  staticClass: "button-spinner",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { src: "/img/lightbox/preloader.gif" },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2805334927
                ),
              },
              "DialogComponent",
              { closeDialog: _vm.closeDialog, dialogSize: _vm.dialogSize },
              false
            ),
            [
              _c("div", { attrs: { id: "main-children" } }, [
                _c("div", {}, [
                  _c("div", { staticClass: "cover" }, [
                    _c("div", { staticClass: "item-one" }, [
                      _c("img", {
                        staticClass: "circle-avatar-two",
                        attrs: {
                          src: "/img/sample-profile-pic/profile-man-1.jpg",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "item-two" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v("Amaka Suliat"),
                      ]),
                      _c("span", { staticClass: "role" }, [
                        _vm._v("Backend Engineer, Lagos, Nigeria"),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-title" }, [
      _c("h3", { staticStyle: { "text-align": "left" } }, [
        _vm._v("About your team"),
      ]),
      _c("p", [
        _vm._v(
          "\n        Add a team bio and a video introducing your team as simply as you can.\n        Your team would be shown to only clients who select work modes & service\n        plans that match yours\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [_c("hr", { staticClass: "hr" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }