var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vetting-progress" }, [
      _c(
        "div",
        { staticClass: "progress-wrapper" },
        _vm._l(_vm.vettingStages, function (stage, k) {
          return _c("div", { key: k }, [
            _c(
              "div",
              {
                staticClass: "progress-stage",
                on: {
                  click: function ($event) {
                    return _vm.switchStage(k)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src:
                      "/img/onboarding/" +
                      (_vm.currentStage == k
                        ? "green-dot.svg"
                        : _vm.currentStage < k
                        ? "black-dot.svg"
                        : "tick-icon.svg"),
                    alt: "",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "progress-name",
                    style:
                      _vm.currentStage == k
                        ? "color: #1fa564;"
                        : _vm.currentStage < k
                        ? "color: #979da5;"
                        : "color: #1b1e22;",
                  },
                  [_vm._v("\n            " + _vm._s(stage) + "\n          ")]
                ),
              ]
            ),
            k + 1 < _vm.vettingStages.length
              ? _c("div", {
                  staticClass: "progress-line",
                  style:
                    _vm.currentStage == k
                      ? "border-left: solid 2px #54CF94;"
                      : _vm.currentStage < k
                      ? "border-left:solid 2px #e4e5e7;"
                      : "border-left: solid 3px #1b1e22",
                })
              : _vm._e(),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }