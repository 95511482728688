<template>
  <div class="team-servicesplan-wapper">
    <div class="section-title">How you’ll work</div>
    <div class="section-description">
      Select how best you would like to work as a team. Your team would be shown
      to only clients who select work modes & service plans that match yours.
    </div>
    <TeamWorkMode
      v-bind="{
        teamDetails,
        handleUpdateSetting,
        createWorkMode,
        updateTeamWorkMode,
        teamAvailableWorkMode,
      }"
    />
    <ServicePlans
      v-bind="{
        teamDetails,
        handleUpdateSetting,
        teamServicePlans,
        createWorkMode,
        updateTeamWorkMode,
      }"
    />
  </div>
</template>

<script>
import {
  teamSettings,
  fetchTeamWorkMode,
  selectServicePlan,
  updateServicePlan,
} from "@/api/team";
import { mapActions, mapState } from "vuex";
import TeamWorkMode from "./workMode";
import ServicePlans from "./servicePlans";
export default {
  props: {},
  components: {
    TeamWorkMode,
    ServicePlans,
  },
  data: () => ({
    grupaWorkMode: [
      {
        enabled: true,
        name: "Distributed",
        description: `Every member of the team can work from any location anywhere in the world.`,
        price: `Standard pricing based on service plans`,
        img: "distributed-icon.svg",
        selected: false,
        id: 1,
      },
      {
        enabled: false,
        name: "Remote",
        description: `Every member of the team works from a remote hub/office chosen by the client.`,
        img: "remote-icon.svg",
        price: `1.2x of Standard Prices`,
        id: 2,
        selected: false,
      },
      {
        enabled: false,
        name: "Onsite",
        description: `Every member of the team works from an onsite location chosen by the client.`,
        img: "onsite-icon.svg",
        price: `1.5x of Standard Prices`,
        selected: false,
        id: 3,
      },
    ],
    grupaServicePlans: [
      {
        id: 1,
        name: "Upstarts",
        hours: "100 work hours per week per person",
        week: "8 weeks per iteration",
        price: "Starts at $10,000 per week",
        team: `The client gets a 5-man team.
(1 Technical PM, 1 QA, 2 Engineers, 1 Designer)`,
        img: "upstart-icon.svg",
        selected: true,
        workModeId: 1,
      },
      {
        id: 2,
        name: "Hypergrowth",
        hours: "180 work hours per week per person",
        week: "6 weeks per iteration",
        price: "Starts at $16,000 per week",
        team: `The client gets a 6-man team.
(1 PM, 1 QA, 3 Engineers, 1 Designer)`,
        img: "growth-icon.svg",
        selected: false,
        workModeId: 1,
      },
      {
        id: 3,
        name: "Blitzscale",
        hours: "280 work hours per week per person",
        week: "4 weeks per iteration",
        price: "Starts at $20,000 per week",
        team: `The client gets a 7-man team.
(1 PM, 1 QA, 4 Engineers, 1 Designer)`,
        img: "scale-icon.svg",
        selected: false,
        workModeId: 1,
      },
    ],
    teamWorkModeArray: [],
  }),

  created() {
    this.getTeamWorkMode();
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    handleUpdateSetting(payload) {
      teamSettings(payload)
        .then((response) => {
          if (response.data.status == 1) {
            this.setSuccessMsg("Service plan saved!!");
          } else if (response.data.status == 0) {
            this.setErrorMsg("Please try again!!");
          }
        })
        .catch((error) => {
          this.setErrorMsg("Please try again!!");
        });
    },
    async getTeamWorkMode() {
      const teamId = this.teamDetails.id;
      await fetchTeamWorkMode(teamId)
        .then((resp) => {
          this.teamWorkModeArray = resp.data.data;
        })
        .catch((err) => {});
    },
    createWorkMode(payload) {
      const teamId = this.teamDetails.id;
      payload.team_id = teamId;
      if (this.teamActivation == 85) {
        payload.is_team_activated = 1;
      }
      selectServicePlan(payload)
        .then((resp) => {
          if (resp.data.status == 1) {
            this.setSuccessMsg("Service plan saved!!");
            let respObj = resp.data.data;
            respObj.active = 1;
            this.teamWorkModeArray.push(respObj);
          }
        })
        .catch((err) => {});
    },
    updateTeamWorkMode(payload) {
      let workModes = this.teamWorkModeArray;
      if (this.teamActivation == 85) {
        payload.is_team_activated = 1;
      }
      console.log(payload)
      updateServicePlan(payload)
        .then((resp) => {
          if (resp.data.status == 1) {
            let objIndex = workModes.findIndex((work) => work.id == payload.id);
            this.teamWorkModeArray[objIndex] = resp.data.data;
            this.setSuccessMsg("Service plan saved!!");
          }
        })
        .catch((err) => {});
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo", "teamActivation"]),
    teamDetails() {
      return this.teamInfo.team;
    },
    // This function here is not scalable, this is not best practices, the backend guy is refuse to return me the right data
    teamAvailableWorkMode() {
      if (this.teamWorkModeArray) {
        this.grupaWorkMode.map((work) => {
          this.teamWorkModeArray.map((teamSelect) => {
            if (
              work.id == teamSelect.project_work_mode_id &&
              teamSelect.active
            ) {
              work.selected = true;
              work.rowId = teamSelect.id;
            } else if (
              work.id == teamSelect.project_work_mode_id &&
              !teamSelect.active
            ) {
              work.selected = false;
              work.rowId = teamSelect.id;
            }
          });
        });
      }
      return this.grupaWorkMode;
    },
    teamServicePlans() {
      if (this.teamWorkModeArray) {
        this.grupaServicePlans.map((plan) => {
          this.teamWorkModeArray.map((teamSelect) => {
            if (
              plan.id == teamSelect.service_plan_id &&
              teamSelect.active &&
              plan.workModeId == teamSelect.project_work_mode_id
            ) {
              plan.selected = true;
              plan.rowId = teamSelect.id;
            } else if (
              plan.id == teamSelect.service_plan_id &&
              !teamSelect.active &&
              plan.workModeId == teamSelect.project_work_mode_id
            ) {
              plan.selected = false;
              plan.rowId = teamSelect.id;
            }
          });
        });
      }
      return this.grupaServicePlans;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.team-servicesplan-wapper {
  text-align: left;
}
.section-title {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
}
.section-description {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  width: 477px;
  margin-top: 8px;
}
</style>
