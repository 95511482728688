<template>
  <div>
    <div class="interview-section">
      <div class="top-div">
        <div class="congrate-msg">Talk to us</div>
        <div class="text-grey">
          Let’s have a chat to better understand your expectations and how Grupa
          can best serve your needs.
        </div>
      </div>
      <div class="schedule-meeting" v-if="!oboarding.meeting_start_date">
        <button class="grupa-blue-btn" @click="handleScheduleMeeting">
          Schedule Meeting
        </button>
      </div>
      <!-- <div class="schedule-meeting" v-if="oboarding.meeting_start_date">
        <button class="grupa-white-blue-btn" @click="handleScheduleMeeting">
          Reschedule Meeting
        </button>
      </div> -->
      <div v-if="oboarding.meeting_start_date" class="meeeting-details">
        <div class="meeting-time">
          <div><img src="/img/onboarding/schedule-icon.svg" /></div>
          <div>
            Meeting scheduled for
            <span v-if="convertedMeetingDate">
              {{ convertedMeetingDate }}
            </span>
          </div>
        </div>
        <div class="meeting-link">
          <a target="_blank" :href="oboarding.hangout_link">
            <div class="join-meeting-div">Join Meeting</div>
          </a>
        </div>
      </div>
    </div>
    <CalendlyModal
      v-if="showCalendly"
      v-on:closeModal="closeCalendly"
      v-bind:teamInfo="teamBio"
      v-bind="{ meetLink }"
    />
    <MeetingSuccess
      v-if="isMeetingSuccess"
      v-bind:closeModal="closeSuccessModal"
      v-bind="{ meetingLoader, meetingPayload }"
    />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>
<script>
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import Toolbar from "../components/toolbar";
import CalendlyModal from "@/components/calendlyModal";
import AlertError from "@/components/alertError";
import MeetingSuccess from "@/components/meetingSuccess";
import { googleCalendarEvents, scheduleMeeting } from "@/api";
import { calendlyEndpoint } from "@/api/calendlyApi";
import { updateVideoInterviewStatus } from "@/api/team";
import { getUserEvent } from "@/utils/googleCalenderFxn";
import appMixin from "@/mixins/appMixin";
import mixPanel from "@/mixins/mixPanel";

export default {
  mixins: [appMixin, mixPanel],
  components: {
    Toolbar,
    CalendlyModal,
    MeetingSuccess,
    AlertError,
  },
  data() {
    return {
      openDialog: false,
      showCalendly: false,
      meetingLoader: true,
      meetingPayload: null,
      isMeetingSuccess: false,
      dialogSpinner: false,
      userTimeZone: "",
      meetLink: "https://calendly.com/sam-grupa-io/grupa-intro?",
    };
  },
  created() {
    window.addEventListener("message", async (e) => {
      if (this.isCalendlyEvent(e)) {
        this.$developConsole("Event Scheduled", e.data);
        // calendly.event_scheduled
        //  calendly.date_and_time_selected
        if (e.data.event == "calendly.event_scheduled") {
          this.showCalendly = false;
          this.isMeetingSuccess = true;
          let uri = e.data.payload.event.uri;
          // await this.getUserMeeting();
          await this.fetchMeetingObject(uri);
        }
      }
    });
  },
  destroyed() {
    window.removeEventListener("message", function (e) {});
  },

  async mounted() {
    this.ctrlDialog();
    this.getUserTimeZone();
  },
  methods: {
    ...mapActions("miniProject", ["setMiniProject"]),
    ...mapActions("devDashboard", ["updateCurrentUser"]),

    ...mapActions("alertMsg", ["clearErrorMsg", "setErrorMsg"]),

    ctrlDialog() {
      if (this.currentUser.is_view_video_interview == 0) {
        this.openDialog = true;
      }
    },
    closeDialog() {
      this.openDialog = false;
    },
    closeCalendly() {
      this.showCalendly = false;
    },

    isCalendlyEvent: function (e) {
      return e.data.event && e.data.event.indexOf("calendly") === 0;
    },

    async getUserMeeting() {
      const userEmail = this.currentUser.email;
      await googleCalendarEvents()
        .then(async (resp) => {
          if (resp.data.status == 1) {
            const eventDetails = getUserEvent(resp.data.events, userEmail);
            this.meetingPayload = eventDetails;
            await this.bookMeeting(eventDetails);
          } else {
            this.meetingLoader = false;
            throw new Error(resp.data.msg);
          }
        })
        .catch((err) => {
          this.meetingLoader = false;
          this.$developConsole(err, "Unable to fetch");
        });
    },
    closeSuccessModal() {
      if (!this.meetingLoader) {
        this.isMeetingSuccess = false;
      }
    },
    async bookMeeting(data) {
      await scheduleMeeting(data)
        .then((resp) => {
          this.meetingLoader = false;
          this.setMiniProject(resp.data);
          this.mixPanelTrack({
            event: "ScheduleMeetingSuccess",
            email: this.currentUser.email,
          });
        })
        .catch((error) => {
          this.meetingLoader = false;
          this.mixPanelTrack({
            event: "ScheduleMeetingFailed",
            email: this.currentUser.email,
          });
        });
    },
    handleCompleteProfile() {
      const { work_experience_count, address, kyc } = this.currentUser;
      if (work_experience_count == 0) {
        this.$router.push({ name: "work_experience" });
      } else if (work_experience_count > 0 && !address) {
        this.$router.push({ name: "personal_data" });
      } else if (work_experience_count > 0 && address && kyc == 0) {
        this.$router.push({ name: "upload_kyc" });
      }
    },
    handleScheduleMeeting() {
      // if (this.currentUser.team_lead == 1) {
      this.showCalendly = true;
      // } else {
      //   this.setErrorMsg("This action can only be performed by the team");
      // }
    },
    async handleRemoveDialog() {
      this.dialogSpinner = true;
      await updateVideoInterviewStatus()
        .then((resp) => {
          if (resp.data.status == 1) {
            this.updateCurrentUser(resp.data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.dialogSpinner = false;
          this.openDialog = false;
        });
    },
    getUserTimeZone() {
      Axios.get(`https://ipapi.co/${this.userLocation.ip}/timezone`).then(
        (response) => {
          this.userTimeZone = response.data;
        }
      );
    },
    convertedStartDate(start_date) {
      let userTimeZone = this.userTimeZone;
      let newDate = this.changeTimezone(start_date, userTimeZone);
      let momentDate = this.$moment(newDate).format(
        "dddd, MMMM Do YYYY, h:mm:ss a"
      );
      // this.convertMeetingDate = momentDate;
      return momentDate;
    },
    async fetchMeetingObject(url) {
      await calendlyEndpoint(url, "expert")
        .then((response) => {
          if (response.status == 200) {
            let { end_time, start_time, event_guests, location, created_at } =
              response.data.resource;
            let payload = {
              meeting_attendees: event_guests,
              meeting_end: end_time,
              hangout_link: location.join_url,
              meeting_id: "",
              meeting_start_date: start_time,
              meeting_summary: "",
              meeting_created: "",
            };
            this.meetingPayload = payload;
            this.bookMeeting(payload);
          } else {
            this.meetingLoader = false;
            throw new Error(resp.data.msg);
          }
        })
        .catch((error) => {
          this.meetingLoader = false;
        });
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    ...mapState("miniProject", ["teamMiniProject"]),
    ...mapState("alertMsg", ["errorMsg", "successMsg"]),
    ...mapState("userManagement", ["userLocation"]),

    currentUser() {
      return this.teamInfo.user;
    },
    teamMembers() {
      return this.teamInfo.team.members;
    },
    teamBio() {
      return this.teamInfo;
    },
    devWithIncompleteProfile() {
      let members = this.teamMembers.filter(
        (dev) =>
          !dev.profile_picture || dev.work_experience_count == 0 || dev.kyc == 0
      );
      let firstElement = null;
      members.map((member) => {
        if (member.id == this.currentUser.id) {
          firstElement = this.currentUser;
          let userIndex = members.findIndex(
            (item) => item.id == this.currentUser.id
          );
          members.splice(userIndex, 1);
        }
      });
      if (firstElement == null) {
        return members;
      } else {
        return [firstElement, ...members];
      }
      // return members;
    },
    oboarding() {
      return this.teamMiniProject;
    },
    convertedMeetingDate() {
      if (this.oboarding.meeting_start_date && this.userTimeZone) {
        let start_date = this.oboarding.meeting_start_date;
        return this.convertedStartDate(start_date);
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-container {
  background: #f7f7f8;
  padding: 48px 32px 72px;
  margin-left: 307px;
  margin-top: 64px;
  min-height: calc(100vh - 64px);
}
.success-msg {
  font-size: 12px;
  line-height: 140%;
  color: #1fa564;
  margin-top: 24px;
  width: 356px;
  text-align: left;
}
.top-div {
  width: 521px;
  text-align: left;
}

.congrate-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.text-grey {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
}
.members-text {
  font-size: 14px;
  line-height: 130%;
  color: #a5271f;
  margin-top: 24px;
}
.schedule-meeting {
  text-align: left;
  margin-top: 48px;
}
.meeeting-details {
  margin-top: 48px;
  width: 563px;
  height: 193px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}
.meeting-time {
  font-size: 16px;
  line-height: 130%;
  color: #979da5;
}
.meeting-time span {
  color: #1b1e22;
}
.meeting-link {
  margin-top: 26px;
  display: flex;
  justify-content: center;
}
.meeting-link a {
  text-decoration: none;
}
.join-meeting-div {
  border-radius: 3px;
  padding: 16px 20px;
  font-size: 14px;
  line-height: 100%;
  background: #0781f2 !important;
  color: #fdfdfd !important;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  width: 146px;
}
@media (max-width: 1280px) {
  .page-container {
    margin-left: 250px;
  }
}
@media (max-width: 768px) {
  .top-div,
  .meeeting-details {
    width: unset;
  }
}
</style>