export const getUserEvent = (arrayList, userEmail) => {
  let meetingDetails;
  let teamMeetings = [];
  let meetingArray = arrayList.reverse();
  for (let i = 0; i < meetingArray.length; i++) {
    if (meetingArray[i].attendees) {
      meetingArray[i].attendees.map((member) => {
        if (member.email == userEmail) {
          teamMeetings.push(meetingArray[i]);
        }
      });
    }
  }
  if (teamMeetings.length != 0) {
    meetingDetails = teamMeetings[0];
  } else {
    meetingDetails = meetingArray[0];
  }
  const {
    attendees,
    created,
    end,
    hangoutLink,
    start,
    id,
    summary,
  } = meetingDetails;
  let payload = {
    meeting_attendees: attendees,
    meeting_created: created,
    meeting_end: end.dateTime,
    hangout_link: hangoutLink,
    meeting_id: id,
    meeting_start_date: start.dateTime,
    meeting_summary: start.timeZone,
  };
  return payload;
};
