var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "service-plan-wrapper" }, [
    _c("div", { staticClass: "top-title" }, [
      _c("div", { staticClass: "container-title" }, [_vm._v("SERVICE PLANS")]),
      _c(
        "div",
        { staticClass: "selected-serviceplan" },
        [
          _vm._v("\n      Your team is available for:\n      "),
          _vm._l(_vm.activePlans, function (plan, i) {
            return _c("span", { key: i }, [
              _c("span", [_vm._v(_vm._s("" + plan.name.toUpperCase()))]),
              _c("span", [
                _vm._v(
                  _vm._s(i == _vm.activePlans.length - 2 ? "&" : ",") + " "
                ),
              ]),
            ])
          }),
          _c("span", [_vm._v("SERVICE PLANS")]),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "container-instruction" }, [
      _vm._v(
        "\n    Select which service plan(s) your team would be available for\n  "
      ),
    ]),
    _c(
      "div",
      { staticClass: "serviceplan-container" },
      _vm._l(_vm.teamServicePlans, function (plan, i) {
        return _c("div", { key: i, staticClass: "serviceplan-card" }, [
          _c("div", { staticClass: "plan-details" }, [
            _c("div", { staticClass: "plan-info" }, [
              _c("div", { staticClass: "serviceplan-name" }, [
                _vm._v(_vm._s(plan.name)),
              ]),
              _c("div", { staticClass: "plan-description" }, [
                _vm._v("\n            " + _vm._s(plan.team) + "\n          "),
              ]),
              _c("div", { staticClass: "team-work-hour" }, [
                _vm._v(_vm._s(plan.hours)),
              ]),
              _c("div", { staticClass: "plan-hours" }, [
                _vm._v(_vm._s(plan.week)),
              ]),
              _c("div", { staticClass: "plan-price" }, [
                _c("img", {
                  attrs: { src: _vm.imageLink + "pricetag.svg", alt: "" },
                }),
                _vm._v("\n            " + _vm._s(plan.price) + "\n          "),
              ]),
            ]),
            _c("div", { staticClass: "plan-icon" }, [
              _c("img", { attrs: { src: _vm.imageLink + plan.img, alt: "" } }),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "serviceplan-action",
              on: {
                click: function ($event) {
                  return _vm.handleSwitchPaln(plan)
                },
              },
            },
            [
              _c("div", [
                plan.selected
                  ? _c("img", {
                      attrs: {
                        src: _vm.imageLink + "active-toggle.svg",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: _vm.imageLink + "inactive-toggle.svg",
                        alt: "",
                      },
                    }),
              ]),
              plan.selected
                ? _c("div", { staticClass: "available" }, [_vm._v("Available")])
                : _c("div", { staticClass: "un-available" }, [
                    _vm._v("Unavailabe"),
                  ]),
            ]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }