<template>
  <div>
    <div class="container-wrapper">
      <div class="left-container">
        <div class="vetting-title">Invite teammates</div>
        <div class="vetting-description">
          Work with your friends again. The best product teams are made up of
          experts in
          <span> Engineering </span>
          , <span>Product</span> and <span>Data science</span>.
        </div>
        <div class="vetting-description space-margin">
          Invite your own team from your network of people.
        </div>
        <div class="email-error">
          <span v-if="formError.emails">
            {{ formError.emails }}
          </span>
        </div>
        <div class="invitation-form">
          <div v-if="team.members && team.members.length > 1">
            <div
              v-for="(member, index) in team.members"
              :key="index"
              class="team-members-container"
              v-if="!member.team_lead"
            >
              <div class="member-details">
                <div class="profile-picture">
                  <v-avatar
                    :size="48"
                    color="grey lighten-4"
                    class="profile_bg"
                    :style="{ backgroundImage: `url(${imageAvatar})` }"
                  ></v-avatar>
                </div>
                <div class="full-details">
                  <div class="developer-name">
                    {{ member.name ? member.name : "Unregistered" }}
                  </div>
                  <div class="developer-email">
                    {{ member.email }}
                  </div>
                </div>
              </div>
              <div class="sent-invite">
                <div class="resend-btn">
                  {{
                    member.activation_level > 0
                      ? "Invite Accepted"
                      : "Invite Sent"
                  }}
                </div>
              </div>
              <div @click="handleRevokeInvite(member)" class="revoke-invite">
                Revoke Invite
              </div>
            </div>
          </div>
          <div class="form-container">
            <v-combobox
              class="form-control overright-input"
              v-model="form.emails"
              append-icon="none"
              persistent-hint
              id="teamMembers"
              small-chips
              solo
              flat
              placeholder="friend@emailaddress.com"
              multiple
              @keypress="handleComma($event)"
            ></v-combobox>
            <span @click="handleSendInvite"
              >Send Invites
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </span>
          </div>
          <div class="invite-box">
            <div class="checkbox-div">
              <v-checkbox v-model="form.skipInvites"></v-checkbox>
            </div>
            <div class="check-invite">Invite teammates later</div>
          </div>
          <div class="button-wrapper">
            <button
              v-if="
                (team.members && team.members.length > 1) || form.skipInvites
              "
              class="grupa-blue-btn"
              @click="proceedToNextStage(3)"
            >
              Proceed to Talk to us
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="btn_spinner"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="right-container"></div>
    </div>
    <RemoveTeamMember
      v-bind:spinner="removeSpinner"
      v-bind:handleDelete="handleRemoveDeveloper"
      v-bind:developerName="removeDevInfo.email"
      v-bind:closeDialog="closeDialog"
      v-if="isRemoveDialog"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { inviteTeamMates, updateActivationLevel, removeTeamMate } from "@/api";
import mixPanel from "@/mixins/mixPanel";
import RemoveTeamMember from "@/components/Modals/removeTeamMember.vue";
export default {
  props: {},
  components: {
    RemoveTeamMember,
  },
  mixins: [mixPanel],
  data: () => ({
    form: {
      emails: [],
      skipInvites: false,
    },
    formError: {
      emails: "",
    },
    spinner: false,
    removeSpinner: false,
    imageAvatar: "/img/onboarding/profile-avatar.svg",
    btn_spinner: false,
    isRemoveDialog: false,
    removeDevInfo: {},
  }),

  created() {},
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", [
      "updateTeamActivationLevel",
      "updateTeamMembers",
      "updateCurrentStage",
    ]),
    handleComma(event) {
      if (event.key == "," || event.key == " ") {
        event.preventDefault();
        let el = document.getElementById("teamMembers");
        var e = new KeyboardEvent("keydown", {
          bubbles: true,
          cancelable: true,
          char: "Tab",
          key: "Tab",
          shiftKey: true,
          keyCode: 9,
        });
        el.dispatchEvent(e);
      } else {
        event;
      }
    },
    handleSendInvite() {
      this.handleBlurEmails();
      let { emails } = this.form;
      if (emails.length == 0) {
        this.formError.emails = "Kindly invite your developer friends";
      }
      if (emails.length > 0) {
        this.spinner = true;
        let emailsArray = emails.map((email) => {
          return {
            email: email,
            text: "",
          };
        });
        const payload = {
          emails: emailsArray,
        };
        inviteTeamMates(payload)
          .then((response) => {
            this.$developConsole(response.data, "team members");
            this.updateTeamMembers(response.data.data);
            this.mixPanelTrack({
              event: "InviteTeam",
              email: this.currentUser.email,
            });
            this.setSuccessMsg("Invitation Sent!!");
            this.form.emails = [];
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              this.formError.emails = error.response.data.msg;
            }
            this.mixPanelTrack({
              event: "InviteTeamFailed",
              email: this.currentUser.email,
            });
          })
          .finally(() => {
            this.spinner = false;
          });
      }
    },
    handleBlurEmails() {
      let el = document.getElementById("teamMembers");
      var e = new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        char: "Tab",
        key: "Tab",
        shiftKey: true,
        keyCode: 9,
      });
      el.dispatchEvent(e);
    },
    proceedToNextStage(stage) {
      if (this.activationLevel < 3) {
        this.btn_spinner = true;
        let payload = { team_activation_level: stage };
        updateActivationLevel(payload)
          .then((response) => {
            this.btn_spinner = false;
            this.updateTeamActivationLevel(response.data.data);
            this.updateCurrentStage(3);
            this.mixPanelTrack({
              event: "ProceedToScheduleMeeting",
            });
          })
          .catch((err) => {
            this.btn_spinner = false;
            this.$developConsole("Mini Project Err", err.response);
          });
      } else {
        this.updateCurrentStage(3);
      }
    },
    handleRevokeInvite(member) {
      this.removeDevInfo = member;
      this.isRemoveDialog = true;
    },
    closeDialog() {
      this.isRemoveDialog = false;
    },
    handleRemoveDeveloper() {
      const { id } = this.removeDevInfo;
      let payload = { user_id: id };
      this.removeSpinner = true;
      removeTeamMate(payload)
        .then((response) => {
          if (response.data.status) {
            let newMembers = JSON.parse(JSON.stringify(this.team.members));
            let devIndex = newMembers.findIndex((user) => user.id == id);
            newMembers.splice(devIndex, 1);
            let data = { members: newMembers };
            this.updateTeamMembers(data);
            this.isRemoveDialog = false;
            this.setSuccessMsg("Team Member Removed");
          } else {
            this.setErrorMsg(response.data.msg);
          }
        })
        .catch((err) => {
          this.setErrorMsg(err.response.data.msg);
        })
        .finally(() => {
          this.removeSpinner = false;
        });
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("devDashboard", ["teamInfo"]),
    team() {
      return this.teamInfo.team;
    },
    currentUser() {
      return this.teamInfo.user;
    },
    activationLevel() {
      return this.teamInfo.team.activation_level;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.invite-box {
  display: flex;
  margin-top: 16px;
}
.checkbox-div .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.check-invite {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  text-align: left;
  margin-top: 3px;
}
.container-wrapper {
  display: flex;
}
.left-container {
  width: 596px;
}
.vetting-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #1b1e22;
}
.vetting-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 4px;
  width: 478px;
}
.vetting-description span {
  color: #1b1e22;
}
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 48px 0px;
}
.space-margin {
  margin-top: 16px;
}
.invitation-form {
  /* margin-top: 48px; */
}
.email-error {
  margin-top: 16px;
  height: 16px;
  color: red;
  font-size: 14px;
  margin-bottom: 16px;
  text-align: left;
}
.form-container {
  position: relative;
  width: 596px;
}
.form-container span {
  position: absolute;
  top: 35%;
  right: 18px;
  cursor: pointer;
  color: #0781f2;
  font-size: 14px;
  line-height: 130%;
}
.team-members-container {
  display: flex;
  justify-content: space-between;
  padding: 24px 12px;
  align-items: center;
  border-bottom: 1px solid #e4e5e7;
}
.member-details {
  display: flex;
}
.full-details {
  margin-left: 12px;
}
.developer-name {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #e4e5e7;
}
.developer-email {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.resend-btn {
  background: rgba(84, 207, 148, 0.1);
  border-radius: 12px;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #54cf94;
  padding: 6px 16px;
  cursor: pointer;
}
.revoke-invite {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* display: flex; */
  color: #53585f;
  padding: 16px 20px;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .left-container {
    width: 496px;
  }
  .form-container {
    width: 496px;
  }
}
@media (max-width: 768px) {
  .form-container,
  .left-container,
  .vetting-description {
    width: unset;
  }
}
</style>
