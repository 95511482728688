<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">Remove Teammate</div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="resend-msg">
          You are about to remove <b>{{ developerName }}</b> from your team
        </div>
        <div class="continue-msg">Do you wish to continue?</div>
      </div>
      <div class="dialog-footer">
        <button
          class="grupa-grey-btn"
          @click="closeDialog"
          style="margin-right: 24px"
        >
          Cancel
        </button>
        <button class="grupa-red-btn" @click="handleDelete">
          Remove
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="spinner"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    spinner: Boolean,
    handleDelete: {
      type: Function,
      required: true,
    },
    developerName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
  width: 417px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}
.delete-msg {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
  width: 284px;
}
.continue-msg {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 24px;
  text-align: left;
}
</style>


