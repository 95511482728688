var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-workmode-wrapper" }, [
    _c("div", { staticClass: "top-title" }, [
      _c("div", { staticClass: "container-title" }, [_vm._v("WORK MODES")]),
      _c(
        "div",
        { staticClass: "selected-workmode" },
        [
          _vm._v("\n      Your team is available for:\n      "),
          _vm._l(_vm.activeWorkmode, function (plan, i) {
            return _c("span", { key: i }, [
              _c("span", [_vm._v(_vm._s("" + plan.name.toUpperCase()))]),
              _c("span", [
                _vm._v(
                  _vm._s(i == _vm.activeWorkmode.length - 2 ? "&" : ",") + " "
                ),
              ]),
            ])
          }),
          _c("span", [_vm._v("WORK MODES")]),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "container-instruction" }, [
      _vm._v(
        "\n    Select which work mode(s) your team would be available for\n  "
      ),
    ]),
    _c(
      "div",
      { staticClass: "workmode-container" },
      _vm._l(_vm.teamAvailableWorkMode, function (plan, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "work-mode-card",
            style: !plan.enabled ? "opacity: 0.3;" : null,
          },
          [
            _c("div", { staticClass: "card-header-div" }, [
              _c("div", [
                _c("img", {
                  attrs: { src: _vm.imageLink + plan.img, alt: "" },
                }),
              ]),
              _c("div", { staticClass: "workmode-name" }, [
                _vm._v(_vm._s(plan.name)),
              ]),
            ]),
            _c("div", { staticClass: "workmode-description" }, [
              _vm._v("\n        " + _vm._s(plan.description) + "\n      "),
            ]),
            _c("div", { staticClass: "workmode-price" }, [
              _c("img", {
                attrs: { src: _vm.imageLink + "pricetag.svg", alt: "" },
              }),
              _vm._v(_vm._s(plan.price) + "\n      "),
            ]),
            _c(
              "div",
              {
                staticClass: "workmode-action",
                on: {
                  click: function ($event) {
                    return _vm.handleSwitchWorkMode(plan)
                  },
                },
              },
              [
                _c("div", [
                  plan.selected
                    ? _c("img", {
                        attrs: {
                          src: _vm.imageLink + "active-toggle.svg",
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: _vm.imageLink + "inactive-toggle.svg",
                          alt: "",
                        },
                      }),
                ]),
                plan.selected
                  ? _c("div", { staticClass: "available" }, [
                      _vm._v("Available"),
                    ])
                  : _c("div", { staticClass: "un-available" }, [
                      _vm._v("Unavailabe"),
                    ]),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }