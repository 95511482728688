var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-wrapper" }, [
        _c("div", { staticClass: "left-container" }, [
          _c("div", { staticClass: "vetting-title" }, [
            _vm._v("Invite teammates"),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "vetting-description space-margin" }, [
            _vm._v(
              "\n        Invite your own team from your network of people.\n      "
            ),
          ]),
          _c("div", { staticClass: "email-error" }, [
            _vm.formError.emails
              ? _c("span", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.formError.emails) + "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "invitation-form" }, [
            _vm.team.members && _vm.team.members.length > 1
              ? _c(
                  "div",
                  _vm._l(_vm.team.members, function (member, index) {
                    return !member.team_lead
                      ? _c(
                          "div",
                          { key: index, staticClass: "team-members-container" },
                          [
                            _c("div", { staticClass: "member-details" }, [
                              _c(
                                "div",
                                { staticClass: "profile-picture" },
                                [
                                  _c("v-avatar", {
                                    staticClass: "profile_bg",
                                    style: {
                                      backgroundImage:
                                        "url(" + _vm.imageAvatar + ")",
                                    },
                                    attrs: {
                                      size: 48,
                                      color: "grey lighten-4",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "full-details" }, [
                                _c("div", { staticClass: "developer-name" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        member.name
                                          ? member.name
                                          : "Unregistered"
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _c("div", { staticClass: "developer-email" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(member.email) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "sent-invite" }, [
                              _c("div", { staticClass: "resend-btn" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      member.activation_level > 0
                                        ? "Invite Accepted"
                                        : "Invite Sent"
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "revoke-invite",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRevokeInvite(member)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Revoke Invite\n            "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "form-container" },
              [
                _c("v-combobox", {
                  staticClass: "form-control overright-input",
                  attrs: {
                    "append-icon": "none",
                    "persistent-hint": "",
                    id: "teamMembers",
                    "small-chips": "",
                    solo: "",
                    flat: "",
                    placeholder: "friend@emailaddress.com",
                    multiple: "",
                  },
                  on: {
                    keypress: function ($event) {
                      return _vm.handleComma($event)
                    },
                  },
                  model: {
                    value: _vm.form.emails,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "emails", $$v)
                    },
                    expression: "form.emails",
                  },
                }),
                _c("span", { on: { click: _vm.handleSendInvite } }, [
                  _vm._v("Send Invites\n            "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.spinner,
                        expression: "spinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "invite-box" }, [
              _c(
                "div",
                { staticClass: "checkbox-div" },
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.form.skipInvites,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "skipInvites", $$v)
                      },
                      expression: "form.skipInvites",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "check-invite" }, [
                _vm._v("Invite teammates later"),
              ]),
            ]),
            _c("div", { staticClass: "button-wrapper" }, [
              (_vm.team.members && _vm.team.members.length > 1) ||
              _vm.form.skipInvites
                ? _c(
                    "button",
                    {
                      staticClass: "grupa-blue-btn",
                      on: {
                        click: function ($event) {
                          return _vm.proceedToNextStage(3)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            Proceed to Talk to us\n            "
                      ),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_spinner,
                            expression: "btn_spinner",
                          },
                        ],
                        staticClass: "button-spinner",
                        staticStyle: { "margin-left": "20px" },
                        attrs: { src: "/img/lightbox/preloader.gif" },
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "right-container" }),
      ]),
      _vm.isRemoveDialog
        ? _c("RemoveTeamMember", {
            attrs: {
              spinner: _vm.removeSpinner,
              handleDelete: _vm.handleRemoveDeveloper,
              developerName: _vm.removeDevInfo.email,
              closeDialog: _vm.closeDialog,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vetting-description" }, [
      _vm._v(
        "\n        Work with your friends again. The best product teams are made up of\n        experts in\n        "
      ),
      _c("span", [_vm._v(" Engineering ")]),
      _vm._v("\n        , "),
      _c("span", [_vm._v("Product")]),
      _vm._v(" and "),
      _c("span", [_vm._v("Data science")]),
      _vm._v(".\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }