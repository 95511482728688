var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VettingProgress"),
      _c("Toolbar", { attrs: { stageTitle: "Team" } }),
      _c(
        "div",
        { staticClass: "section-wrapper" },
        [
          !_vm.currentStage ? _c("CreateTeam") : _vm._e(),
          _vm.currentStage == 1 ? _c("SharePortfolio") : _vm._e(),
          _vm.currentStage == 2 ? _c("InviteTeam") : _vm._e(),
          _vm.currentStage == 3 ? _c("TalkToGrupa") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }