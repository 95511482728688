<template>
  <div>
    <Toolbar v-bind="{ tabs, activeTab, switchTabs }">
      <div v-if="activeTab == 'portfolio'">
        <button class="grupa-blue-btn" @click="addPortfolio()">
          Add Product
        </button>
      </div>
    </Toolbar>
    <Loader v-if="teamData == null" />
    <div v-else class="team-mgt">
      <div v-if="activeTab == 'profile'">
        <TeamProfile />
      </div>
      <div v-if="activeTab == 'portfolio'" class="completed">
        <TeamPortfolio ref="teamPortfolio" />
      </div>
      <div v-if="activeTab == 'service_plans'">
        <TeamServicePlans />
      </div>
    </div>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getDev } from "@/api";
import Toolbar from "./toolbar/toolbar";
import TeamProfile from "./profile";
import TeamPortfolio from "./portfolios";
import TeamServicePlans from "./servicePlan";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import Loader from "@/components/general/centerLoader";
export default {
  props: {},
  components: {
    Toolbar,
    Loader,
    TeamProfile,
    TeamPortfolio,
    TeamServicePlans,
    AlertSuccess,
    AlertError,
  },
  data: () => ({
    tabs: [
      {
        id: 1,
        name: "Profile",
        active: true,
        tab: "profile",
      },
      {
        id: 2,
        name: "Portfolio",
        tab: "portfolio",
        active: false,
      },
      {
        id: 3,
        name: "Service Plans",
        tab: "service_plans",
        active: false,
      },
      // {
      //   id: 4,
      //   name: "Settings",
      //   tab: "settings",
      //   active: false,
      // },
      // {
      //   id: 5,
      //   name: "Integrations",
      //   tab: "integrations",
      //   active: false,
      // },
    ],
    activeTab: "profile",
    teamData: null,
  }),
  created() {
    this.getTeamDetails();
    this.openTab();
  },
  methods: {
    ...mapActions("devDashboard", ["setTeamDetails"]),
    switchTabs(tab) {
      this.activeTab = tab;
    },
    addPortfolio() {
      this.$refs.teamPortfolio.handleAddPortfolioModal();
    },
    async getTeamDetails() {
      await getDev()
        .then((res) => {
          if (res.data) {
            this.teamData = res.data;
            this.$developConsole(res.data, "Team data fetched...");
            this.setTeamDetails(res.data);
            this.dataFetched = true;
          }
        })
        .catch((err) => {});
    },
    openTab() {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
      }
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.team-mgt {
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
  padding: 32px;
}
</style>