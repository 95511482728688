<template>
  <div>
    <div class="team-portfolio-container">
      <div class="section-title">Products you’ve built</div>
      <div class="section-description">
        Upload details of products you have built together as a team. At least 2
        members of the team must have worked together on these products
      </div>
      <div class="portfolios-list">
        <AddUpdatePortfolio
          v-if="isAddDialog"
          v-bind="{
            closeDialog,
            portfolioForm,
            form_errors,
            portfolioIndustries,
            handleAddPortfolio,
            spinner,
            imageURL,
            onFileChange,
            isNewPortfolio,
          }"
        />
        <PortfolioCard
          v-for="(portfolio, index) in teamPortfolios"
          :key="index"
          v-bind:portfolio="portfolio"
          v-bind="{ deleteAPortfolio, editAPortfolio }"
        />
        <DeletePortfolio
          v-if="openDialog"
          v-bind="{ closeDialog, spinner, handleDelete }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { productSectors, devPortfolio, editPortfolio } from "@/api";
import { deletePortfolio } from "@/api/team";
import PortfolioCard from "./portfolioCard";
import AddUpdatePortfolio from "./addUpdatePortfolio";
import DeletePortfolio from "@/components/Modals/deletePortfolio";
export default {
  props: {},
  components: {
    PortfolioCard,
    AddUpdatePortfolio,
    DeletePortfolio,
  },

  data: () => ({
    openDialog: false,
    isAddDialog: false,
    form: {
      description: "",
      link: "http://",
      image: "",
    },
    portfolioForm: {
      description: "",
      link: "http://",
      image: "",
    },
    form_errors: {
      description: [],
      link: [],
      image: [],
    },
    portfolioIndustries: [],
    spinner: false,
    imageURL: "",
    currentBanner: "",
    delPortfolioId: 0,
    isNewPortfolio: true,
  }),

  created() {},
  methods: {
    ...mapActions("devDashboard", ["addPortfolio", "updateTeamPortfolio"]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    handleAddPortfolioModal() {
      this.isAddDialog = true;
      this.isNewPortfolio = true;
      this.portfolioForm = {
        image: "",
        description: "",
        link: "",
      };
      this.imageURL = "";
    },
    editAPortfolio(payload) {
      this.isAddDialog = true;
      this.isNewPortfolio = false;
      let { banner, description, link, id } = payload;
      this.portfolioForm = {
        image: banner,
        description,
        link,
        id,
      };

      this.imageURL = banner.includes("http")
        ? banner
        : this.$portfolioUrl + banner;
      this.currentBanner = this.imageURL;
    },
    closeDialog() {
      this.isAddDialog = false;
      this.openDialog = false;
    },
    deleteAPortfolio(id) {
      this.delPortfolioId = id;
      this.openDialog = true;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.imageURL = URL.createObjectURL(file);
      this.portfolioForm.image = e.target.files[0];
    },
    projectIndustries() {
      productSectors().then((response) => {
        this.portfolioIndustries = response.data.data;
      });
    },
    handleAddPortfolio() {
      this.form_errors = {
        description: [],
        link: [],
        image: [],
      };
      let checkMak = /^http/;
      if (!this.portfolioForm.description) {
        this.form_errors.description[0] =
          "Please fill in your project description";
      }
      if (!this.portfolioForm.link) {
        this.form_errors.link[0] = "Please fill in your project link";
      }
      if (this.imgUploadSource == "/img/dashboard/dev/addIconThin.svg") {
        this.form_errors.image[0] = "Please upload your project image";
      }
      if (
        this.form_errors.description.length == 0 &&
        this.form_errors.image.length == 0 &&
        this.form_errors.link.length == 0
      ) {
        this.spinner = true;
        let data = new FormData();
        if (this.currentBanner !== this.imageURL) {
          data.append("banner", this.portfolioForm.image);
        }
        data.append("description", this.portfolioForm.description);
        data.append("link", this.portfolioForm.link);
        if (this.isNewPortfolio) {
          this.teamAddPortfolio(data);
        } else {
          this.teamEditPortfolio(data);
        }
      }
    },
    handleDelete() {
      this.spinner = true;
      let id = this.delPortfolioId;
      deletePortfolio(id)
        .then((resp) => {
          let portfolioList = JSON.parse(JSON.stringify(this.teamPortfolios));
          let portIndex = portfolioList.findIndex((port) => port.id == id);
          portfolioList.splice(portIndex, 1);
          this.updateTeamPortfolio(portfolioList);
          this.setSuccessMsg("Portfolio Deleted!!");
        })
        .catch((err) => {
          this.setErrorMsg("Please try again!!");
        })
        .finally(() => {
          this.openDialog = false;
          this.spinner = false;
        });
    },
    teamAddPortfolio(payload) {
      devPortfolio(payload)
        .then((response) => {
          if (response.data.status == 1) {
            this.spinner = false;
            this.isAddDialog = false;
            this.portfolioForm = this.form;
            this.imageURL = "";
            this.addPortfolio(response.data.data);
            this.setSuccessMsg("Portfolio Uploaded!!");
            this.$developConsole(response.data.data, "Upload feedback");
          } else if (response.data.status == 0) {
            this.spinner = false;
            this.setErrorMsg("Please try again!!");
          }
        })
        .catch((error) => {
          this.$developConsole(error.response);
          if (error.response.data.status == 0) {
            this.spinner = false;
            this.setErrorMsg("Please try again!!");
          }
        });
    },
    teamEditPortfolio(payload) {
      let portId = this.portfolioForm.id;
      editPortfolio(portId, payload)
        .then((response) => {
          if (response.data.status == 1) {
            let portfolioList = JSON.parse(JSON.stringify(this.teamPortfolios));
            let portIndex = portfolioList.findIndex(
              (port) => port.id == portId
            );
            portfolioList[portIndex] = response.data.data;
            this.spinner = false;
            this.isAddDialog = false;
            this.portfolioForm = this.form;
            this.imageURL = "";
            this.updateTeamPortfolio(portfolioList);
            this.setSuccessMsg("Portfolio Updated!!");
          } else if (response.data.status == 0) {
            this.spinner = false;
            this.setErrorMsg("Please try again!!");
          }
        })
        .catch((error) => {
          this.$developConsole(error.response);
          if (error.response.data.status == 0) {
            this.spinner = false;
            this.setErrorMsg("Please try again!!");
          }
        });
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    teamPortfolios() {
      return this.teamInfo.portfolio;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.team-portfolio-container {
  text-align: left;
}
.section-title {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
}
.section-description {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  width: 477px;
  margin-top: 8px;
}
.portfolios-list {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}
</style>
