<template>
  <div>
    <div class="container-wrapper">
      <div class="left-container">
        <div class="vetting-title">Create your team</div>
        <div class="vetting-description">
          We believe truly great products are built by the best teams. Tell us a
          bit about the team you’re putting together
        </div>
        <div class="team-form">
          <!-- <form action=""> -->
          <div class="form-label">
            <div>Team name</div>
            <div class="form_errors" v-show="form_errors.team_name[0]">
              {{ form_errors.team_name[0] }}
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              name="name"
              class="form-control"
              v-model="form.team_name"
              id="name"
              placeholder="What name will your team be called?"
            />
          </div>
          <div class="form-label">
            <div>Enter a short bio for your team</div>
            <div class="form_errors" v-show="form_errors.bio[0]">
              {{ form_errors.bio[0] }}
            </div>
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              rows="4"
              style="height: unset"
              placeholder="e.g. Mike and I were the first employees at Uber"
              v-model="form.bio"
            >
            </textarea>
          </div>
          <div class="global_error">
            <span v-show="form_errors.backend">{{
              form_errors.backend[0]
            }}</span>
          </div>
          <div class="submit-btn">
            <button
              class="grupa-disabled-btn"
              :class="enableBtn ? 'grupa-blue-btn' : ''"
              :disabled="!enableBtn"
              @click="handleCreateTeam"
            >
              Proceed to Share Portfolio
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
          </div>
          <!-- </form> -->
        </div>
      </div>
      <div class="right-container"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import countriesTimeZones from "countries-and-timezones";
import { teamLeadCreateTeam } from "@/api";
import mixPanel from "@/mixins/mixPanel";
export default {
  props: {},
  components: {},
  mixins: [mixPanel],
  data: () => ({
    form: {
      team_name: null,
      country: null,
      timezone: "",
      bio: "",
    },
    form_errors: {
      team_name: [],
      bio: [],
      backend: [],
    },
    spinner: false,
  }),
  mounted() {
    this.setTeamDetails();
  },
  methods: {
    ...mapActions("devDashboard", ["updateTeamData", "updateCurrentStage"]),
    handleCreateTeam() {
      this.form_errors = {
        team_name: [],
        bio: [],
        backend: [],
      };
      if (!this.form.team_name) {
        this.form_errors.team_name[0] = "Team name required";

        this.form_errors.backend[0] = "Incomplete form";
      } else if (
        this.form.team_name.length < 5 ||
        this.form.team_name.length > 20
      ) {
        this.form_errors.backend[0] = "Team name: 5 - 20 characters required";

        this.form_errors.team_name[0] = "5 - 20 characters required.";
      } else if (this.form.bio == "") {
        this.form_errors.bio[0] = "Team bio is required.";
      } else {
        this.$developConsole(this.form, "All data to end point");
        this.spinner = true;
        const { country_name, country_code } = this.userLocation;
        if (country_code) {
          let timeZones = countriesTimeZones.getCountry(`${country_code}`);
          this.form.timezone = timeZones.timezones[0];
        }
        this.form.country = country_name;

        console.log(this.form);
        teamLeadCreateTeam(this.form)
          .then((response) => {
            this.updateTeamData(response.data.data);
            this.updateCurrentStage(1);
            this.spinner = false;
            if (response.data.msg == "errors") {
              this.form_errors = Object.assign(
                this.form_errors,
                response.data.data
              );
            } else if (response.data.status == 0) {
              this.$developConsole(response, "Error message");
            } else if (response.data.status == 1) {
              this.mixPanelTrack({
                event: "CreateTeam",
                email: "",
                teamName: this.form.team_name,
              });
            }
          })
          .catch((error) => {
            this.$developConsole(error.response, "Error message Catch");
            this.spinner = false;
            if (error.response.data.status == 0) {
              this.form_errors.backend[0] = error.response.data.msg;
            }
            this.mixPanelTrack({
              event: "CreateTeamFailed",
              email: "",
              teamName: this.form.team_name,
            });
          });
      }
    },
    setTeamDetails() {
      const { team_name, bio } = this.teamInfo.team;
      this.form = { team_name, bio };
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser", "userLocation"]),
    ...mapState("devDashboard", ["teamInfo"]),

    enableBtn() {
      if (this.form.team_name && this.form.bio) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.container-wrapper {
  display: flex;
}
.left-container {
  width: 596px;
}
.vetting-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #1b1e22;
}
.vetting-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 4px;
  width: 478px;
}
.form-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  display: flex;
  color: #1b1e22;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
}
.form-group,
.form-control {
  margin-bottom: 0px;
}
.submit-btn {
  margin-top: 48px;
  display: flex;
  justify-content: flex-end;
}
.form_errors {
  float: right;
  color: red;
}
.global_error {
  height: 22px;
  color: red;
  margin-top: 21px;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .vetting-description {
    width: unset;
  }

  .submit-btn {
    justify-content: flex-start;
  }
}
</style>