var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "team-portfolio-container" }, [
      _c("div", { staticClass: "section-title" }, [
        _vm._v("Products you’ve built"),
      ]),
      _c("div", { staticClass: "section-description" }, [
        _vm._v(
          "\n      Upload details of products you have built together as a team. At least 2\n      members of the team must have worked together on these products\n    "
        ),
      ]),
      _c(
        "div",
        { staticClass: "portfolios-list" },
        [
          _vm.isAddDialog
            ? _c(
                "AddUpdatePortfolio",
                _vm._b(
                  {},
                  "AddUpdatePortfolio",
                  {
                    closeDialog: _vm.closeDialog,
                    portfolioForm: _vm.portfolioForm,
                    form_errors: _vm.form_errors,
                    portfolioIndustries: _vm.portfolioIndustries,
                    handleAddPortfolio: _vm.handleAddPortfolio,
                    spinner: _vm.spinner,
                    imageURL: _vm.imageURL,
                    onFileChange: _vm.onFileChange,
                    isNewPortfolio: _vm.isNewPortfolio,
                  },
                  false
                )
              )
            : _vm._e(),
          _vm._l(_vm.teamPortfolios, function (portfolio, index) {
            return _c(
              "PortfolioCard",
              _vm._b(
                { key: index, attrs: { portfolio: portfolio } },
                "PortfolioCard",
                {
                  deleteAPortfolio: _vm.deleteAPortfolio,
                  editAPortfolio: _vm.editAPortfolio,
                },
                false
              )
            )
          }),
          _vm.openDialog
            ? _c(
                "DeletePortfolio",
                _vm._b(
                  {},
                  "DeletePortfolio",
                  {
                    closeDialog: _vm.closeDialog,
                    spinner: _vm.spinner,
                    handleDelete: _vm.handleDelete,
                  },
                  false
                )
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }