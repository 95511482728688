<template>
  <div class="service-plan-wrapper">
    <div class="top-title">
      <div class="container-title">SERVICE PLANS</div>
      <div class="selected-serviceplan">
        Your team is available for:
        <span v-for="(plan, i) in activePlans" :key="i">
          <span>{{ `${plan.name.toUpperCase()}` }}</span>
          <span>{{ i == activePlans.length - 2 ? "&" : "," }} </span>
        </span>
        <span>SERVICE PLANS</span>
      </div>
    </div>
    <div class="container-instruction">
      Select which service plan(s) your team would be available for
    </div>
    <div class="serviceplan-container">
      <div
        class="serviceplan-card"
        v-for="(plan, i) in teamServicePlans"
        :key="i"
      >
        <div class="plan-details">
          <div class="plan-info">
            <div class="serviceplan-name">{{ plan.name }}</div>
            <div class="plan-description">
              {{ plan.team }}
            </div>
            <div class="team-work-hour">{{ plan.hours }}</div>
            <div class="plan-hours">{{ plan.week }}</div>
            <div class="plan-price">
              <img :src="imageLink + 'pricetag.svg'" alt="" />
              {{ plan.price }}
            </div>
          </div>
          <div class="plan-icon">
            <img :src="imageLink + plan.img" alt="" />
          </div>
        </div>
        <div class="serviceplan-action" @click="handleSwitchPaln(plan)">
          <div>
            <img
              v-if="plan.selected"
              :src="imageLink + 'active-toggle.svg'"
              alt=""
            />
            <img v-else :src="imageLink + 'inactive-toggle.svg'" alt="" />
          </div>
          <div v-if="plan.selected" class="available">Available</div>
          <div v-else class="un-available">Unavailabe</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { teamSettings } from "@/api/team";
export default {
  props: {
    teamDetails: {
      required: true,
      type: Object,
    },
    handleUpdateSetting: {
      required: true,
      type: Function,
    },
    createWorkMode: {
      required: true,
      type: Function,
    },
    teamServicePlans: {
      required: true,
      type: Array,
    },
    updateTeamWorkMode: {
      required: true,
      type: Function,
    },
  },
  components: {},

  data: () => ({
    imageLink: "/img/dashboard/workmode/",
    activePlan: true,
  }),

  created() {},
  methods: {
    handleSwitchPaln(plan) {
      if (!plan.rowId) {
        const payload = { project_work_mode_id: 1, service_plan_id: plan.id };
        this.createWorkMode(payload);
      } else {
        const payload = { id: plan.rowId, active: !plan.selected };
        this.updateTeamWorkMode(payload);
      }
    },
  },
  computed: {
    activePlans() {
      return this.teamServicePlans.filter((plan) => plan.selected == true);
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.service-plan-wrapper {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
  text-align: left;
}
.top-title {
  display: flex;
  justify-content: space-between;
}
.container-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.selected-serviceplan {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
}
.selected-serviceplan span {
  color: #1fa564;
}
.container-instruction {
  font-size: 14px;
  line-height: 130%;
  margin-top: 24px;
  color: #1b1e22;
}
.serviceplan-container {
  display: flex;
  margin-top: 16px;
}
.serviceplan-card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px 19px 19px 24px;
  width: 365px;
  margin-right: 16px;
}
.plan-details {
  /* display: flex; */
  position: relative;
}
.plan-icon {
  position: absolute;
  top: 2px;
  right: 0px;
}
.serviceplan-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.plan-price,
.plan-hours,
.team-work-hour,
.plan-description {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 15px;
  width: 269px;
}
.plan-price,
.plan-hours {
  margin-top: 12px;
}
.serviceplan-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
  cursor: pointer;
}
.un-available,
.available {
  margin-top: 2px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 8px;
}
.un-available {
  color: #53585f;
}
</style>
