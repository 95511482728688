<template>
  <div class="p">
    <div class="page-title">
      <h3 style="text-align: left">About your team</h3>
      <p>
        Add a team bio and a video introducing your team as simply as you can.
        Your team would be shown to only clients who select work modes & service
        plans that match yours
      </p>
    </div>
   
      <!-- First section -->
      <div class="page-row">
        <div class="items">
          <div class="item-cover">
            <div class="item">
              <span class="first">INTRODUCTORY VIDEO</span>
              <span class="second mouse" @click="openFile()">Upload</span>
              <input
            type="file"
            accept="video/*"
            @change="onFileChange"
            name="profilePhoto"
            size="chars"
            class="input-file"
          />
            </div>
            <div v-if="!videoLink" class="item item-vid">
              <img src="/img/placeholder.png" />
            </div>
            <div v-else class="iframe-wrapper">
              <v-progress-circular class="video-spinner"
      :size="50"
      color="primary"
      indeterminate
      v-if="videoSpinner"
    ></v-progress-circular>
              <iframe width="369" height="244" :src="videoLink">
               
</iframe>
            </div>
            <div class="item vid-text">
              For best presentation, upload a video not longer than 90 seconds.
              Talk about the makeup, expertise and culture of your team.
            </div>
          </div>
        </div>
        <div class="items">
          <div class="item-cover">
            <div class="item">
              <span class="first">Team Name</span>
              <span @click="openTeamDetailsDialog = true" class="second mouse"
                >Edit</span
              >
            </div>
            <div class="item small-top-space">
              <span class="first bold">{{ teamObject.team_name }}</span>
              <!-- <span class="second">Edit</span> -->
            </div>
            <div class="item">
              <hr class="hr" />
            </div>

            <div class="item small-top-space">
              <span class="first">
                <span class="first">
                    BIO
                </span>
                <span class="third">
                    {{teamObject.bio}}
                </span>
              </span>
              <!-- <span class="second middle-border">
                  <span class="first small-left-padding">
                    LOCATION
                  </span>
                  <span class="second">
                    <!-- {{teamObject.country}} ( GMT {{getTimeZone(teamObject.timezone)}}) -->
                  <!-- </span>
                </span> --> 
            </div>
            </div>
        </div>
        </div>

      <!-- Section Section -->
      <div class="page-row full-width">
        <div class="items">
          <div class="item">
            <span class="first">MEMBERS ({{ members.length }})</span>
            <span @click="openDialog = true" class="second mouse">Invite</span>
          </div>
          <div class="item-cover-2">
            <div v-for="(member,k) in members" :key="member.id" class="item">
              
              <div class="first">
                <div class="team-lead-icon" v-if="member.team_lead==1">
                <img src="/img/dashboard/team-lead.svg" alt="">
              </div>
                <div class="first-block">
                  <div class="content">
                    <div class="content-two">
                      <img
                      v-if="member.profile_picture"
                        class="circle-avatar"
                        :src="$imageUrl + member.profile_picture || '/img/profile.svg'"
                      />
                      <div
                  v-if="member.name && !member.profile_picture"
                  class="profile-avatar"
                  :style="(k + 1) % 2 == 0 ? 'background: #1FA564;' : null"
                >
                  {{ abbrevateName(member.name).toUpperCase() }}
                </div>
                <v-avatar
                  v-if="!member.name"
                  :size="64"
                  color="grey lighten-4"
                  class="profile_bg"
                  :style="{ backgroundImage: `url(${imageAvatar})` }"
                ></v-avatar>
                <div>
                      <span class="name">{{ member.name }}</span>
                      <span class="skill">{{ member.team_role }}</span>
                      <span class="name">{{ member.email }}</span>
                </div>

                    </div>      
                    <div class="content-four">
                      <!-- <span
                      v-if="currentUser.id !==member.id"
                       class="view-profile">View Profile</span> -->
                      <span class="view-profile" 
                      v-if="currentUser.team_lead == 1 && member.team_lead ==1"
                      @click="openTransferTeamLeadModal(member)">Transfer TeamLead</span>
                      <span v-if="!member.team_lead" class="remove-member" @click="openRemoveTeamModal(member)">
                        Remove
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
                  

      <DialogComponent
        dialogTitle="Invite Teammate"
        v-if="openDialog"
        v-bind="{ closeDialog, dialogSize }"
      >
        <div id="main-children">
          <div class="">
            <span class="modal-inner-title">Enter teammate email & role </span>
            <input
              class="form-control"
              name="email"
              v-model="invitedUser.email"
              placeholder="teammate@example.com"
              type="email"
            />

            <v-select
          class="vue_select"
          v-model="invitedUser.teamSelected"
          :items="roles"
          label="-- Select Team Role --"
          return-object
          solo
          flat
          light
          item-text="name"
          item-value="id"
        ></v-select>

          </div>
        </div>

        <template v-slot:dialog-footer>
          <div>
            <button
              @click="closeDialog"
              class="grupa-grey-btn"
              style="margin-right: 24px"
            >
              Cancel
            </button>
            <button class="grupa-blue-btn" @click="inviteTeam">
              Send Invite
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
          </div>
        </template>
      
    </DialogComponent>

      <DialogComponent
        dialogTitle="Edit Team Info"
        v-if="openTeamDetailsDialog"
        v-bind="{ closeDialog, dialogSize }"
      >
        <div id="main-children">
          <div class="">
            <span class="modal-inner-title"
              >Edit team bio. To edit your team name please Contact Support.
               <!-- <a href="#">Contact Support</a> -->
              </span
            >
            <input
              class="form-control"
              name="email"
              v-model="teamObject.team_name"
              placeholder="Team name"
              type="text"
              :disabled="false"
            />

            <!-- <select
              class="select-cont"
              name="role"
              style="margin-bottom: 0"
              v-model="selectedSpecialization"
            >
              <option disabled value>-- specializations --</option>
              <option
                :value="item"
                v-for="item in specializationsArr"
                :key="item.name"
              >
                {{ item.name }}
              </option>
            </select> -->

            <!-- <input
              class="form-control form-control-margin"
              name="location"
              v-model="teamObject.country"
              placeholder="Location"
              type="text"
            /> -->
              <!-- <vue-google-autocomplete
                ref="autocomplete"
                id="map"
                classname="form-control addressTextBox"
                placeholder="Location"
                v-on:placechanged="getAddressData"
                types=""
              >
              </vue-google-autocomplete> -->
            <textarea
              class="form-control text-area-form-control"
              v-model="teamObject.bio"
            ></textarea>
          </div>
        </div>

        <template v-slot:dialog-footer>
          <div>
            <button
              @click="closeDialog"
              class="grupa-grey-btn"
              style="margin-right: 24px"
            >
              Cancel
            </button>
            <button
              :disabled="secSpinnerTwo"
              class="grupa-blue-btn"
              @click="editEntireTeam"
            >
              Update
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="secSpinnerTwo"
              />
            </button>
          </div>
        </template>
      </DialogComponent>

      <DialogComponent
        dialogTitle="Remove Teammate"
        v-if="openRemoveTeamMateDialog"
        v-bind="{ closeDialog, dialogSize }"
      >
        <div id="main-children">
          <div class="">
            <span class="modal-inner-title"
              > You are about to
              remove {{ currentToBeRemovedUser.name?currentToBeRemovedUser.name:currentToBeRemovedUser.email }} from your team, this can not be reversed.</span
            >
            <span
              class="wish-to-continue-txt"
              style="color: #979DA5;float:left;text-align:left:width:100%"
              >Do you wish to continue?</span
            >
          </div>
        </div>

        <template v-slot:dialog-footer>
          <div>
            <button
              @click="closeDialog"
              class="grupa-grey-btn"
              style="margin-right: 24px"
            >
              No, Go Back
            </button>
            <button
              :disabled="secSpinnerTwo"
              class="grupa-blue-btn grupa-btn-red"
              @click="removeTeamMate()"
            >
              Yes, Remove
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="secSpinnerTwo"
              />
            </button>
          </div>
        </template>
      </DialogComponent>

      <DialogComponent
        dialogTitle="Transfer Team Lead"
        v-if="openTransferTeamLeadDialog"
        v-bind="{ closeDialog, dialogSize }"
      >
        <div id="main-children">
          <div class="">
            <span class="modal-inner-title">Select A New Team Leader</span>

            <select
              class="select-cont"
              name="role"
              style="margin-bottom: 0"
              v-model="newSelectedTeamLead"
            >
              <option disabled value>-- Select the new team lead --</option>
              <option
                :value="member"
                v-for="member in members"
                :key="member.id"
              >
                {{ member.name }}, {{ member.email }}
              </option>
            </select>
          </div>
        </div>

        <template v-slot:dialog-footer>
          <div>
            <button
              @click="closeDialog"
              class="grupa-grey-btn"
              style="margin-right: 24px"
            >
              Cancel
            </button>
            <button
              :disabled="secSpinnerTwo"
              class="grupa-blue-btn"
              @click="transfTeamLead()"
            >
              Transfer
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="secSpinnerTwo"
              />
            </button>
          </div>
        </template>
      </DialogComponent>

      <DialogComponent
        dialogTitle="Member name"
        v-if="openViewProfileDialog"
        v-bind="{ closeDialog, dialogSize }"
      >
        <div id="main-children">
          <div class="">
            <div class="cover">
              <div class="item-one">
                <img
                  class="circle-avatar-two"
                  src="/img/sample-profile-pic/profile-man-1.jpg"
                />
              </div>
              <div class="item-two">
                <span class="name">Amaka Suliat</span>
                <span class="role">Backend Engineer, Lagos, Nigeria</span>
              </div>
            </div>
          </div>
        </div>

        <template v-slot:dialog-footer>
          <div>
            <button
              @click="closeDialog"
              class="grupa-grey-btn"
              style="margin-right: 24px"
            >
              Cancel
            </button>
            <button
              :disabled="secSpinnerTwo"
              class="grupa-blue-btn"
              @click="transfTeamLead()"
            >
              Transfer
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="secSpinnerTwo"
              />
            </button>
          </div>
        </template>
        </DialogComponent>
      <AlertSuccess v-if="successMsg" time="7" />
      <AlertError v-if="errorMsg" time="7" />
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import DialogComponent from "@/components/Modals/dialogComponent";
import RemoveTeamMember from "@/components/Modals/removeTeamMember";

import {
  getRoles,
  editTeam,
  removeTeamUser,
  transferTeamLead,
  uploadIntroVideo,
} from "@/api/team";
import { mapActions, mapState } from "vuex";
import { getSepcializations, addNewTeamMember, removeTeamMate } from "@/api";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import countryTimeZones from "countries-and-timezones";
import shortName from "@/mixins/shortName";

export default {
  props: {},
  components: {
    DialogComponent,
    AlertSuccess,
    AlertError,
    RemoveTeamMember,
    VueGoogleAutocomplete,
  },
  mixins: [shortName],
  data: () => ({
    removeSpinner: false,
    openRemoveDialog: false,
    imageAvatar: "/img/onboarding/profile-avatar.svg",
    openDialog: false,
    openTeamDetailsDialog: false,
    openRemoveTeamMateDialog: false,
    openTransferTeamLeadDialog: false,
    openViewProfileDialog: false,
    dialogSize: true,
    teamSelected: "",
    invitedUser: {
      email: "",
      teamSelected: "",
    },
    roles: [],
    secSpinner: false,
    secSpinnerTwo: false,
    spinner: false,
    specializationsArr: [],
    selectedSpecialization: "",
    currentToBeRemovedUser: {},
    currentTeamLead: {},
    newSelectedTeamLead: {},
    imageAvatar: "/img/onboarding/profile-avatar.svg",

    items: ["Fullstack Developer", "Backend Developer", "Frontend Developer"],
    video_name: "",
    videoLink: "",
    videoSpinner: false,
  }),

  created() {
    this.members;
    this.getDevRoles();
    this.getAllSepcializations();
  },
  methods: {
    ...mapActions("devDashboard", [
      "updateSpecializations",
      "updateTeamData",
      "updateTeamMembers",
    ]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    closeDialog() {
      this.openDialog = false;
      this.openTeamDetailsDialog = false;
      this.openRemoveTeamMateDialog = false;
      this.openTransferTeamLeadDialog = false;
      this.secSpinnerTwo = false;
      this.openRemoveDialog = false;
    },
    inviteTeams() {
      let obj = {
        id: this.members[this.members.length - 1].id + 1,
        email: this.invitedUser.email,
        team_role: this.invitedUser.teamSelected,
        name: this.invitedUser.email,
        team_lead: 0,
      };

      this.members.push(obj);
    },
    inviteTeam() {
      let obj = {
        email: this.invitedUser.email,
        teamSelected: this.invitedUser.teamSelected,
        name: this.invitedUser.email,
      };
      // this.members.push(obj);
      let payload = {
        role: obj.teamSelected,
        email: obj.email,
      };
      this.spinner = true;
      addNewTeamMember(payload)
        .then((response) => {
          if (response.data.status == 1) {
            let member = response.data.data;
            member.activation_level = 0;
            this.members.push(member);
            this.invitedUser.email = "";
            this.invitedUser.teamSelected = "";
            this.setSuccessMsg(
              "Successfull!!! An Invitation link has been sent to the email address"
            );
            this.openDialog = false;
          } else if (response.data.status == 0) {
            this.setErrorMsg(response.data.msg);
          } else {
            this.setErrorMsg("Email already exist, kindly try another email");
          }
        })
        .catch((error) => {
          if (error.response.data.status == 0) {
            // this.formErrors.emailError = error.response.data.msg;
            this.setErrorMsg(error.response.data.msg);
          } else {
            this.setErrorMsg(error.response.data.msg);
          }
        })
        .finally(() => {
          this.spinner = false;
        });
    },
    async getDevRoles() {
      await getRoles().then((response) => {
        if (response.data.status == 1) {
          this.roles = response.data.data;
        }
      });
    },
    async getAllSepcializations() {
      await getSepcializations()
        .then((response) => {
          if (response.data.status == 1) {
            this.specializationsArr = response.data.data.specialization;
            this.updateSpecializations(response.data.data);
          }
        })
        .catch((e) => {});
    },
    async editEntireTeam() {
      let payload = {
        bio: this.teamObject.bio,
        team_id: this.teamObject.id,
        // specialization: this.selectedSpecialization.name,
        // country: this.teamObject.country,
      };
      if (this.teamActivation == 85) {
        payload.is_team_activated = 1;
      }
      this.secSpinnerTwo = true;
      await editTeam(payload)
        .then((response) => {
          if (response.data.status == 1) {
            this.updateTeamData(response.data.data);
            this.secSpinnerTwo = false;
            this.setSuccessMsg("Team Details has been updated succesfully.");
          }
        })
        .catch((e) => {
          this.secSpinnerTwo = false;
        })
        .finally(() => {
          this.openTeamDetailsDialog = false;
        });
    },
    async removeTeamMate() {
      this.secSpinnerTwo = true;
      if (this.currentToBeRemovedUser.id) {
        let payload = {
          user_id: this.currentToBeRemovedUser.id,
        };
        await removeTeamUser(payload)
          .then((response) => {
            if (response.data.status == 1) {
              this.updateTeamMembers(response.data.data);
              let devIndex = this.members.findIndex(
                (userd) => userd.id == this.currentToBeRemovedUser.id
              );
              this.members.splice(devIndex, 1);
              this.secSpinnerTwo = false;
              this.openRemoveTeamMateDialog = false;
              this.setSuccessMsg("Member has been removed succesfully");
            }
          })
          .catch((err) => {});
      }
    },
    openRemoveTeamModal(user) {
      this.currentToBeRemovedUser = user;
      this.openRemoveTeamMateDialog = true;
    },
    async transfTeamLead() {
      this.secSpinnerTwo = true;
      let payload = {
        user_one: this.currentTeamLead.id,
        user_two: this.newSelectedTeamLead.id,
        team_id: this.currentTeamLead.team_id,
      };
      await transferTeamLead(payload)
        .then((response) => {
          if (response.data.status == 1) {
            let store = {
              members: response.data.data,
            };
            this.updateTeamMembers(store);
            this.secSpinnerTwo = false;
            this.setSuccessMsg("Leadership has been transfered");
          }
        })
        .catch((err) => {});
    },
    openTransferTeamLeadModal(user) {
      this.currentTeamLead = user;
      this.openTransferTeamLeadDialog = true;
    },
    getTimeZone(timeZone) {
      const timezone = countryTimeZones.getTimezone(`${timeZone}`);
      return timezone.dstOffsetStr.split(":")[0];
    },
    openFile() {
      document.querySelector(".input-file").click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.video_name = e.target.files[0];
      const { size, type } = this.video_name;
      if (type.split("/")[0] == "video" && size > 5001000) {
        this.setErrorMsg("The video size must less than 5mb");
      }
      if (type.split("/")[0] == "video" && size <= 5001000) {
        this.videoLink = URL.createObjectURL(file);
        this.handleUploadVideo();
      }
      if (type.split("/")[0] != "video") {
        this.setErrorMsg("Please upload a valid video");
      }
    },
    handleUploadVideo() {
      this.videoSpinner = true;
      let formData = new FormData();
      formData.append("video", this.video_name);
      if (this.teamActivation == 85) {
        formData.append("is_team_activated", 1);
      }
      uploadIntroVideo(formData)
        .then((resp) => {
          if (resp.data.status == 1) {
            this.setSuccessMsg("Video successfully uploaded");
          } else {
            this.setErrorMsg("Network Eror, Please Try Again");
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.videoSpinner = false;
        });
    },
    handleRemoveDeveloper() {
      let reomeObj = { user_id: this.selectedUser.id };
      this.removeSpinner = true;
      removeTeamMate(reomeObj)
        .then((response) => {
          if (response.data.status) {
            let devIndex = this.members.findIndex(
              (userd) => userd.id == this.selectedUser.id
            );
            this.members.splice(devIndex, 1);
            this.openRemoveDialog = false;
            this.setSuccessMsg("Team Member Removed");
          } else {
            this.setErrorMsg(response.data.msg);
          }
        })
        .catch((err) => {
          this.setErrorMsg(err.response.data.msg);
        })
        .finally(() => {
          this.removeSpinner = false;
        });
    },
    getAddressData: function (addressData, placeResultData, id) {
      const { formatted_address } = placeResultData;
      let address;
      formatted_address
        ? (address = formatted_address)
        : (address = document.querySelector(".addressTextBox").value);
      this.teamObject.country = address;
    },
  },
  computed: {
    ...mapState("devDashboard", [
      "teamInfo",
      "team",
      "specializations",
      "teamActivation",
    ]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    teamObject() {
      if (this.teamInfo.team) {
        return { ...this.teamInfo.team };
      }
      return this.teamInfo;
    },
    members() {
      const { videos } = this.teamInfo.team;
      if (videos.length > 0) {
        const lastVideos = videos[videos.length - 1];
        const uploadedVideo = lastVideos.file_name;
        this.videoLink = this.$videoUrl + uploadedVideo;
      }
      if (this.teamInfo.team) {
        return this.teamInfo.team.members;
      }
      return this.teamInfo.members;
    },
    currentUser() {
      return this.teamInfo.user;
    },
    specializations() {
      return this.specializations;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-title {
  float: left;
  width: 100%;
}
.page-title h1,
h3 {
  color: #1b1e22;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}
.page-title p {
  font-size: 14px;
  width: 477px;
  text-align: left;
  color: #979da5;
  line-height: 130%;
}
.page-row {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.page-row .items {
  order: 2; /* default is 0 */
  background: #ffffff;
  border-radius: 4px;
  margin: 10px;
  height: 393px;
  padding: 24px;
  width: 50%;
}
.page-row.full-width .items {
  width: 100%;
  height: auto;
}
.page-row .items div span.first {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
/* .page-row .items div.item-second span.first{display: block;} */
.page-row .items div span.second {
  /* float: right; */
  color: #0781f2;
  line-height: 130%;
}
.small-top-space {
  padding-top: 24px !important;
}
.item-cover-2 {
  grid-column: auto;
  column-count: 2;
}
.item-cover .item {
  display: flex;
}
.item {
  display: flex;
}
.item .second {
  width: 50%;
  text-align: end;
}
.item .first.full-width {
  width: 100%;
}
.item .first {
  width: 100%;
  text-align: start;
  position: relative;
}
.team-lead-icon {
  position: absolute;
  top: 10px;
}
.item span span.first {
  margin-top: 10px;
  display: flex;
}
.item span span.second {
  margin-top: 50px;
  display: flex;
  float: left;
  padding-left: 20px;
  width: 100%;
  cursor: hand;
}
.item span span.third {
  margin-top: 50px;
  display: flex;
  float: left;
  text-transform: capitalize;
}
.bold {
  color: #1b1e22 !important;
  font-size: 14px;
  line-height: 130%;
}
.hr {
  color: #e4e5e7;
  width: 100%;
  margin-top: 24px;
}
.middle-border {
  padding-bottom: 10px;
  border-left: 1px solid #e4e5e7;
}
.small-left-padding {
  padding-left: 20px;
}

.item div div.first-block {
  margin-top: 10px;
  background-color: #f7f7f8;
  padding: 24px;
  margin-right: 10px;
}
.item div div.second-block {
  margin-top: 10px;
  display: flex;
  float: left;
  padding-left: 20px;
  width: 100%;
  background-color: #f7f7f8;
  padding: 25px;
}
.content {
  width: 100%;
  display: flex;
}
.circle-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  vertical-align: middle;
}
.first-block .content .content-two {
  /* width: 45%; */
  display: flex;
}
.second-block .content .content-two {
  width: 40%;
}
.first-block .content .content-three {
  width: 25%;
  display: flex;
  border-right: 1px solid #e4e5e7;
}
.second-block .content .content-three {
  width: 30%;
  display: flex;
  border-right: 1px solid #e4e5e7;
}
.second-block .content .content-three img {
  margin: 0 auto;
}
.first-block .content .content-three img {
  margin: 0 auto;
}
.first-block .content .content-two img {
  float: left;
  margin: 0;
}
.second-block .content .content-two img {
  float: left;
  margin: 0 auto;
}
.first-block .content .content-two span {
  text-align: left;
  display: block;
  padding-left: 10px;
  /* margin-left: 80px; */
}
.second-block .content .content-two span {
  text-align: left;
  display: block;
  padding-left: 10px;
  margin-left: 80px;
}
.skill {
  color: #979da5;
}
.name,
.email {
  color: #1b1e22;
}
.first-block .content .content-four {
  width: 100%;
  display: inline-block;
  float: right;
}
.second-block .content .content-four {
  width: 30%;
  display: flex;
}
.first-block .content .content-four span {
  width: 100%;
}
.second-block .content .content-four span {
  width: 100%;
}
.view-profile {
  color: #53585f;
  padding-top: 12px;
}
.remove-member {
  font-size: 12px;
  line-height: 140%;
  color: #d45b54;
  cursor: pointer;
  padding-top: 12px;
  text-align: right;
  float: right;
}
.first-block .content .content-four .view-profile {
  text-align: right;
}
.item-cover .item {
  display: flex;
}
.item {
  display: flex;
}
.item .second {
  width: 50%;
  text-align: end;
}
.item .first.full-width {
  width: 100%;
}
.item .first {
  width: 100%;
  text-align: start;
}
.item span span.first {
  margin-top: 10px;
  display: flex;
}
.item span span.second {
  margin-top: 50px;
  display: flex;
  float: left;
  padding-left: 20px;
  width: 100%;
  cursor: hand;
}
.item span span.third {
  margin-top: 50px;
  display: flex;
  float: left;
}
.bold {
  color: #1b1e22 !important;
  font-size: 14px;
  line-height: 130%;
}
.hr {
  color: #e4e5e7;
  width: 100%;
  margin-top: 24px;
}
.middle-border {
  padding-bottom: 10px;
  border-left: 1px solid #e4e5e7;
}
.small-left-padding {
  padding-left: 20px;
}
.item div div.first-block {
  margin-top: 10px;
  background-color: #f7f7f8;
  padding: 24px;
  margin-right: 10px;
}
.item div div.second-block {
  margin-top: 10px;
  display: flex;
  float: left;
  padding-left: 20px;
  width: 100%;
  background-color: #f7f7f8;
  padding: 25px;
}
.content {
  width: 100%;
  display: flex;
}
.circle-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  vertical-align: middle;
}
.first-block .content .content-two {
  /* width: 45%; */
}
.second-block .content .content-two {
  width: 40%;
}
.first-block .content .content-three {
  width: 25%;
  display: flex;
  border-right: 1px solid #e4e5e7;
}
.second-block .content .content-three {
  width: 30%;
  display: flex;
  border-right: 1px solid #e4e5e7;
}
.second-block .content .content-three img {
  margin: 0 auto;
}
.first-block .content .content-three img {
  margin: 0 auto;
}
.first-block .content .content-two img {
  float: left;
  margin: 0;
}
.second-block .content .content-two img {
  float: left;
  margin: 0 auto;
}
.first-block .content .content-two span {
  text-align: left;
  display: block;
  padding-left: 10px;
  /* margin-left: 80px; */
}
.second-block .content .content-two span {
  text-align: left;
  display: block;
  padding-left: 10px;
  margin-left: 80px;
}
.skill {
  color: #979da5;
}
.name,
.email {
  color: #1b1e22;
}
.first-block .content .content-four {
  width: 100%;
  display: inline-block;
}
.second-block .content .content-four {
  width: 30%;
  display: flex;
}
.first-block .content .content-four span {
  width: 100%;
}
.second-block .content .content-four span {
  width: 100%;
}
.view-profile {
  color: #53585f;
  padding-top: 10px;
}
.first-block .content .content-four .view-profile {
  text-align: right;
}
.select-cont {
  padding: 10px 10px;
  height: 50px;
  width: 100%;
  margin-bottom: 30px;
}
#main-children {
  width: 320px;
}
.modal-inner-title {
  text-align: left;
  width: 100%;
  padding-bottom: 10px;
  float: left;
}
.mouse {
  cursor: pointer;
}
.little-push {
  padding-bottom: 20px;
}
.first-block .content .content-four span.view-profile {
  width: 100%;
  float: right;
  text-align: right;
}
.first-block .content .content-four span.remove-profile {
  float: right;
  text-align: right;
  color: #d45b54;
}
.first-block .content .content-two span.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-cover-2 .item div div.first-block {
  display: inline-flex;
  width: 100%;
}
.form-control-margin {
  margin-top: 30px;
}
.text-area-form-control {
  min-height: 200px;
}
.grupa-btn-red {
  background: #d45b54 !important;
}
.item.item-vid {
  background: #f7f7f8;
  padding: 85px;
  width: 70%;
  margin-top: 24px;
}
.item.item-vid img {
  margin: 0 auto;
}
.item.vid-text {
  text-align: left;
  color: #53585f;
  margin-top: 24px;
}
.iframe-wrapper {
  text-align: left;
  margin-top: 24px;
  position: relative;
}
.wish-to-continue-txt {
  width: 100%;
  text-align: left;
  float: left;
  margin-bottom: 24px;
}
.circle-avatar-two {
  width: 150px;
  height: 150px;
  float: left;
  vertical-align: left;
  border: none;
}
.cover .item-one {
  width: 50%;
  float: left;
}
span.modal-inner-title {
  text-align: left;
  width: 100%;
  padding-bottom: 10px;
  float: left;
}
.mouse {
  cursor: pointer;
}
.little-push {
  padding-bottom: 20px;
}
.first-block .content .content-four span.view-profile {
  width: 100%;
  float: right;
  text-align: right;
}
.first-block .content .content-four span.remove-profile {
  float: right;
  text-align: right;
  color: #d45b54;
}
.first-block .content .content-two span.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-cover-2 .item div div.first-block {
  display: inline-flex;
  width: 100%;
}
.form-control-margin {
  margin-top: 30px;
}
.text-area-form-control {
  min-height: 200px;
}
.grupa-btn-red {
  background: #d45b54 !important;
}
.item.item-vid {
  background: #f7f7f8;
  padding: 85px;
  width: 70%;
  margin-top: 24px;
}
.item.item-vid img {
  margin: 0 auto;
}
.item.vid-text {
  text-align: left;
  color: #53585f;
  margin-top: 24px;
}
.wish-to-continue-txt {
  width: 100%;
  text-align: left;
  float: left;
}
.circle-avatar-two {
  width: 150px;
  height: 150px;
  float: left;
  vertical-align: left;
  border: none;
}
.cover .item-one {
  width: 50%;
  float: left;
}
.input-file {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}
.video-spinner {
  position: absolute;
  top: 45%;
  left: 30%;
}
</style>
