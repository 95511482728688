var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.currentUser.team_lead == 1
      ? _c(
          "div",
          [
            _vm.team.activation_level < 8 ? _c("TeamVetting") : _vm._e(),
            _vm.team.activation_level == 8 ? _c("TeamData") : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.currentUser.team_lead == 0 ? _c("div", [_c("TeamData")], 1) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }