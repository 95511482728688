<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">
          {{ isNewPortfolio ? "Add" : "Update" }} Product
        </div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="modal-content-div">
          <input
            type="file"
            accept="image/*"
            name="somename"
            size="chars"
            @change="onFileChange"
            class="input_file"
          />
          <div
            v-if="imageURL == '' || imageURL == 'nil'"
            class="add-image-div"
            @click="openFile()"
          >
            <img src="/img/dashboard/banner-avatar.svg" alt="" />
            <div class="upload-info">
              <div>Click to upload product photo</div>
              <div class="image-recommended">
                We recommend that photo should be atleast 2400 x 3200 pixels
              </div>
            </div>
          </div>
          <div
            v-if="imageURL && imageURL !== 'nil'"
            @click="openFile()"
            class="image-cover"
          >
            <div
              class="uploaded-image"
              :style="{ backgroundImage: `url(${imageURL})` }"
            ></div>
          </div>
          <div class="form-container">
            <div class="form-error">
              <span class="error_span" v-if="form_errors.description">{{
                form_errors.description[0]
              }}</span>
            </div>
            <textarea
              name=""
              rows="4"
              v-model="portfolioForm.description"
              placeholder="Enter product description"
              class="port-textarea"
            >
            </textarea>
            <div class="form-error">
              <span class="error_span" v-if="form_errors.link">{{
                form_errors.link[0]
              }}</span>
            </div>
            <input
              type="text"
              v-model="portfolioForm.link"
              class="port-textbox"
              placeholder="Enter link to product"
            />
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
        <button
          style="margin-left: 8px"
          @click="handleAddPortfolio()"
          class="grupa-blue-btn"
        >
          {{ isNewPortfolio ? "Add Product" : "Update" }}
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="spinner"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    portfolioForm: {
      type: Object,
      required: true,
    },
    form_errors: {
      type: Object,
      required: true,
    },
    portfolioIndustries: {
      type: Array,
      required: true,
    },
    handleAddPortfolio: {
      type: Function,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    onFileChange: {
      type: Function,
      required: true,
    },
    imageURL: {
      type: String,
    },
    isNewPortfolio: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    openFile() {
      document.querySelector(".input_file").click();
    },
  },
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
}
.modal-content-div {
  width: 356px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  cursor: pointer;
}
.add-image-div {
  background: #f7f7f8;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
  height: 187px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.image-cover {
  width: 100%;
  height: 187px;
}
.uploaded-image {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: top;
}
.upload-info {
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #979da5;
  margin-top: 12px;
  width: 215px;
}
.image-recommended {
  font-size: 10px;
  margin-top: 16px;
  line-height: 110%;
}

.port-textarea,
.port-textbox {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  padding: 8px 12px;
  width: 100%;
  /* margin-top: 16px; */
  /* margin-bottom: 16px; */
}
.form-error {
  padding-top: 4px;
  color: red;
  font-size: 12px;
  height: 18px;
}
.port-textarea {
  height: 103px;
  resize: none;
}
.form-container {
}
.select-cont {
  padding: 10px 10px;
  height: 40px;
}
.port-textarea:focus,
.port-textbox:focus {
  outline: none;
}
.input_file {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}
</style>
<style>
.select-portfolio {
}
.select-portfolio .v-input__slot {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
}
.select-portfolio .v-input__slot:focus {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
  -moz-transition: all 1s ease-in;
  /* border: 1px solid #6fb5f6; */
  box-sizing: border-box;
}
.select-portfolio .v-input__slot:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
</style>


