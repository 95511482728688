var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-portfolio" }, [
    _c("div", { staticClass: "image-wrapper" }, [
      _c("div", {
        staticClass: "portfolio-banner",
        style: {
          backgroundImage: "url(" + _vm.portfolio.image + ")",
        },
      }),
    ]),
    _c("div", { staticClass: "portfolio-details" }, [
      _c("div", { staticClass: "portfolio-name" }, [
        _vm._v("\n      " + _vm._s(_vm.portfolio.title) + "\n    "),
      ]),
      _c("div", { staticClass: "portfolio-description" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.truncate(_vm.portfolio.description, 120)) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "view-portfolio" }, [
        _vm._v("\n      " + _vm._s(_vm.portfolio.url) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }